import * as learnosityApi from '../api/imagineLearnosityApi';

let templates = null;
let templateNames = null;

export async function getTemplates(token) {
	if (!templates) {
		templates = await learnosityApi.getTemplates(token);
		return templates;
	} else return templates;
}

export async function getTemplateNames(token) {
	let templates = await getTemplates(token);

	if (!templateNames) {
		templateNames = templates
			.map((templateGroups) => templateGroups.templates)
			.map((template) => template.map((x) => x.name))
			.flat();
	}
	return templateNames;
}
