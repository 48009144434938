import React from 'react';
import { textInputFactory } from '../common/TextInput';
import SelectInput from '../common/SelectInput';
import StageBehaviorEntryProps from '../../../types/catConfig/configForm/stageBehaviorProps';
import { Card } from 'react-bootstrap';
import { TESTTYPE_OPTIONS } from '../StageBehaviors';
import Header from './Header';

const TestletFieldTest = ({ handleChange, stageBehavior, entryError, deleteEntry }: StageBehaviorEntryProps) => {
	const createTestletFieldTestTextInput = (name: string, label: string) =>
		textInputFactory(name, label, (event) => handleChange(event, stageBehavior.key), stageBehavior, entryError);
	return (
		<div className="row">
			<div className="col">
				<Card>
					<Card.Header>
						<Header
							stageBehavior={stageBehavior}
							entryError={entryError}
							handleChange={handleChange}
							deleteEntry={deleteEntry}
						/>
					</Card.Header>
					<Card.Body>
						<div className="row">
							<div className="col">
								<SelectInput
									name="testType"
									label="testType"
									onChange={(event) => handleChange(event, stageBehavior.key)}
									placeholder="Test Type"
									value={stageBehavior.testType}
									options={TESTTYPE_OPTIONS}
									error=""
								/>
							</div>
							<div className="col">{createTestletFieldTestTextInput('upperBoundThetaGrade', 'Upper Bound Theta Grade')}</div>
							<div className="col">{createTestletFieldTestTextInput('maxTestlets', 'Max Testlets')}</div>
						</div>
						<div className="row">
							<div className="col">{createTestletFieldTestTextInput('testletActivityIds', 'Testlet Activity IDs (CSV)')}</div>
						</div>
					</Card.Body>
				</Card>
			</div>
		</div>
	);
};

export default TestletFieldTest;
