import React, { useEffect, useState } from 'react';
import NewItemTile from '../../components/NewItemTile/NewItemTile';
import { useHistory, NavLink, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as templateService from '../../services/TemplateService';
import { useOktaAuth } from '@okta/okta-react';

export default function TemplateContainer() {
	let history = useHistory();
	const { authState } = useOktaAuth();
	let { templateGroupName } = useParams();
	const [templateGroup, setTemplateGroup] = useState([]);
	const [templateGroupNames, setTemplateGroupNames] = useState([]);

	useEffect(() => {
		templateService.getTemplates(authState.accessToken).then((templateJson) => {
			setTemplateGroupNames(templateJson.map((x) => x.name));
		});
	}, [authState]);

	useEffect(() => {
		templateService.getTemplates(authState.accessToken).then((templateJson) => {
			let group = templateJson.filter(
				(x) => x.name === (templateGroupName || 'K-1 Multiple Choice & Expanded Selected Response Templates')
			);
			setTemplateGroup(group.length < 1 ? null : group[0]);
		});
	}, [templateGroupName, authState]);

	function onCreateClick(templateGroup) {
		let jsonItem = JSON.stringify(templateGroup.item);
		jsonItem = jsonItem.replace(/ITEM_REF/g, uuid());
		for (let i = 1; i <= templateGroup.numQuestions; i++) {
			let ref = `QUESTION_REF_${i}`;
			let re = new RegExp(ref, 'g');
			jsonItem = jsonItem.replace(re, uuid());
		}
		for (let i = 1; i <= templateGroup.numFeatures; i++) {
			let ref = `FEATURE_REF_${i}`;
			let re = new RegExp(ref, 'g');
			jsonItem = jsonItem.replace(re, uuid());
		}

		history.push('/items/new', { jsonItem: jsonItem, item: templateGroup.item });
	}

	return (
		<>
			<div className="container mx-auto">
				<div className=" grid grid-cols-3 gap-4">
					<div className="flex flex-column my-3">
						{templateGroupNames.map((templateName) => {
							return (
								<NavLink
									to={'/templates/' + templateName}
									activeClassName="text-white bg-gray-800"
									key={templateName}
									className="bg-gray-600 hover:bg-gray-700 focus:outline-none focus:bg-gray-700 active:bg-gray-700 text-white font-bold py-2 px-4">
									{templateName}
								</NavLink>
							);
						})}
					</div>
					<div className="col-span-2 p-3">
						<div className="grid grid-cols-3 gap-6 row-gap-6">
							{templateGroup &&
								templateGroup.templates &&
								templateGroup.templates.map((group) => {
									return (
										<NewItemTile
											key={group.name}
											title={group.name.replace(/_/g, ' ')}
											tile={group.icon}
											handleClick={() => onCreateClick(group)}></NewItemTile>
									);
								})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
