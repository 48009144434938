const ENV = '${DEPLOYMENT_ENV}';

const envs = {
	localhost: {
		issuer: 'https://dev-73067812.okta.com/oauth2/default',
		clientId: '0oa1mjdvsoHVq18A05d7',
		redirectUri: 'http://localhost:3000/implicit/callback',
		logoutUri: 'http://localhost:3000/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: true,
		imagineLearnosityApiUrl: 'http://localhost:5000/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://localcat.imaginelearning.com:4201/ela',
		itemCalibrationBaseURL: 'http://localhost:5001/v1.0/itembankparams/itemParameters',
	},
	test: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oae2v3xnVpCEgbqX4x6',
		redirectUri: 'https://testcat.imaginelearning.com/implicit/callback',
		logoutUri: 'https://testcat.imaginelearning.com/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: false,
		imagineLearnosityApiUrl: 'https://testapi.imaginelearning.com/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://testcat.imaginelearning.com/ela',
		itemCalibrationBaseURL: 'https://item-calibration.galileo-qa-app.wna.cloud/api/api-item-calibration/v1.0/itembankparams/itemParameters',
	},
	rc: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oae2u0u44eUwMoTs4x6',
		redirectUri: 'https://rccat.imaginelearning.com/implicit/callback',
		logoutUri: 'https://rccat.imaginelearning.com/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: false,
		imagineLearnosityApiUrl: 'https://rcapi.imaginelearning.com/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://rccat.imaginelearning.com/ela',
		itemCalibrationBaseURL: 'https://item-calibration.galileo-staging-app.wna.cloud/api/api-item-calibration/v1.0/itembankparams/itemParameters',
	},
	production: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oae2wpi58oORgGbO4x6',
		redirectUri: 'https://cat.imaginelearning.com/implicit/callback',
		logoutUri: 'https://cat.imaginelearning.com/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: false,
		imagineLearnosityApiUrl: 'https://api.imaginelearning.com/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://cat.imaginelearning.com/ela',
		itemCalibrationBaseURL: 'https://item-calibration.galileo-prod-app.wna.cloud/api/api-item-calibration/v1.0/itembankparams/itemParameters',
	},
};

export default function getEnv() {
	if (ENV.includes('$')) {
		return envs.localhost;
	}
	return envs[ENV];
}
