import * as Yup from 'yup';
import {
	requiredTest,
	numericTest,
	integerTest,
	numericMinTest,
	arrayBlankEntryTest,
	arrayEntryNumericTest,
	arrayEntryIntegerTest,
} from './utils/customValidationTests';
import { schemaValidator } from './utils/schemaValidator';
import { parseStringToArrayEntries, parseDecimalInt } from '../formParsingUtils';

const LIMITGENERATEDSTUDENTREPORTSTO_MIN = -1;
const SIMULATEFIRSTNSTUDENTS_MIN = 0;
const NUMBERTHREADS_MIN = 1;
const PRECISION_MIN = 1;

/**
 * These validation rules are not yet correct. They've been set to test all the current validation logic
 * for QA testing purposes.
 */
const SimulationSettingsValidationSchema = Yup.object().shape({
	SimulationTitle: Yup.string().test(requiredTest),
	OutputPath: Yup.string().test(requiredTest),
	StudentFilePath: Yup.string().test(requiredTest),
	ItemsFilePath: Yup.string().test(requiredTest),
	TestletsFilePath: Yup.string().test(requiredTest),
	RandomSeed: Yup.string().test(requiredTest),
	GenerateStudentReports: Yup.string(),
	LimitGeneratedStudentReportsTo: Yup.string()
		.test(requiredTest)
		.test(numericTest)
		.test(integerTest)
		.test(numericMinTest(LIMITGENERATEDSTUDENTREPORTSTO_MIN)),
	SimulateFirstNStudents: Yup.string()
		.test(requiredTest)
		.test(numericTest)
		.test(integerTest)
		.test(numericMinTest(SIMULATEFIRSTNSTUDENTS_MIN)),
	IncludeOnlyLearnosityItems: Yup.string(),
	QuestionTypeFilter: Yup.string().test(requiredTest).test(arrayBlankEntryTest).test(arrayEntryNumericTest).test(arrayEntryIntegerTest),
	AssessmentSubject: Yup.string(),
	SimulateForGrades: Yup.string().test(arrayBlankEntryTest).test(arrayEntryNumericTest).test(arrayEntryIntegerTest),
	NumberThreads: Yup.string().test(requiredTest).test(numericTest).test(integerTest).test(numericMinTest(NUMBERTHREADS_MIN)),
	Precision: Yup.string().test(requiredTest).test(numericTest).test(integerTest).test(numericMinTest(PRECISION_MIN)),
});

export const SimulationSettingsCastSchema = Yup.object().shape({
	SimulationTitle: Yup.string().required(),
	OutputPath: Yup.string().required(),
	StudentFilePath: Yup.string().required(),
	ItemsFilePath: Yup.string().required(),
	TestletsFilePath: Yup.string().required(),
	RandomSeed: Yup.string().required(),
	GenerateStudentReports: Yup.boolean(),
	LimitGeneratedStudentReportsTo: Yup.number().integer().min(LIMITGENERATEDSTUDENTREPORTSTO_MIN).required(),
	SimulateFirstNStudents: Yup.number().integer().min(SIMULATEFIRSTNSTUDENTS_MIN).required(),
	IncludeOnlyLearnosityItems: Yup.boolean(),
	QuestionTypeFilter: Yup.array()
		.of(Yup.number().integer())
		.required()
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, parseDecimalInt);
		}),
	AssessmentSubject: Yup.string(),
	SimulateForGrades: Yup.array()
		.of(Yup.number().integer())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, parseDecimalInt);
		}),
	NumberThreads: Yup.number().integer().min(NUMBERTHREADS_MIN).required(),
	Precision: Yup.number().integer().min(PRECISION_MIN).required(),
});

export const simulationSettingsValidator = schemaValidator(SimulationSettingsValidationSchema);
