import React from 'react';
import StageBehaviorEntryProps from '../../../types/catConfig/configForm/stageBehaviorProps';
import { textInputFactory } from '../common/TextInput';

const Header = ({ stageBehavior, entryError, handleChange, deleteEntry }: StageBehaviorEntryProps) => {
	const createHeaderTextInput = (name: string, label: string) =>
		textInputFactory(name, label, (event) => handleChange(event, stageBehavior.key), stageBehavior, entryError);

	return (
		<div className="row align-items-center">
			<div className="col-2">{createHeaderTextInput('stage', 'Stage')}</div>
			<div className="col">{createHeaderTextInput('chronologicalGradeLevels', 'Chronological Grade Level (CSV)')}</div>
			<div className="col">{createHeaderTextInput('estimatedGradesToSkip', 'Estimated Grades To Skip (CSV)')}</div>
			<div className="col">{createHeaderTextInput('interstitialItemId', 'Interstitial Item Id')}</div>
			<div className="col-1 float-right mr-3">
				<div className="btn btn-outline-danger" onClick={() => deleteEntry(stageBehavior.key)}>
					Delete
				</div>
			</div>
		</div>
	);
};

export default Header;
