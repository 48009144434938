import * as learnosityPermissionsService from './LearnosityPermissionsService';

let itemsInit = {
    config: {
        item_edit: {
            item: {
                back: false,
                actions: {
                    show: true,
                },
                reference: {
                    edit: true,
                    show: true,
                },
                tags: {
                    show: false,
                },
                save: true,
                source: true,
                details: {
                    description: {
                        show: false,
                    },
                    source: {
                        show: true,
                    },
                    difficulty: {
                        show: false,
                    },
                    scoring_type: {
                        show: false,
                    },
                    note: {
                        show: false,
                    },
                    status: {
                        show: false,
                    },
                    reference: {
                        show: false,
                    },
                },
            },
            tags_on_create: [
                {
                    type: 'Product',
                    name: 'Galileo Assessment',
                },
            ],
        },
        widget_templates: {
            save: true
        },
        item_list: {
            item: {
                url: '/items/:reference',
            },
            limit: 50,
            filter: {
                restricted: {
                    tags: {
                        all: [
                            {
                                type: 'Product',
                                name: 'Galileo Assessment',
                            },
                        ],
                    },
                },
            },
        },
        global: {
            hide_tags: [
                {
                    type: 'Article',
                },
                {
                    type: 'Unit',
                },
                {
                    type: 'Grade',
                },
                {
                    type: 'Item DOK',
                },
                {
                    type: 'Item Skill',
                },
                {
                    type: 'Item Type',
                },
            ],
        },
    },
};



let settingsInit = {
    config: {
        item_edit: {
            settings: {
                show: false,
                full_height: true,
            },
            item: {
                back: false,
                reference: {
                    show: false,
                },
                save: true,
                details: {
                    difficulty: {
                        show: false,
                    },
                    scoring_type: {
                        show: false,
                    },

                    note: {
                        edit: true,
                        show: true,
                    },
                },
                columns: false,
                tabs: false,
            },
            tags_on_create: [
                {
                    type: 'Product',
                    name: 'Galileo Assessment',
                },
            ],
        },
        widget_templates: {
            save: true
        },
        item_list: {
            item: {
                url: '/items/:reference',
            },
            limit: 50,
            filter: {
                restricted: {
                    tags: {
                        all: [
                            {
                                type: 'Product',
                                name: 'Galileo Assessment',
                            },
                        ],
                    },
                },
            },
        },
        global: {
            hide_tags: [
                {
                    type: 'Article',
                },
                {
                    type: 'Unit',
                },
                {
                    type: 'Grade',
                },
                {
                    type: 'Item DOK',
                },
                {
                    type: 'Item Skill',
                },
                {
                    type: 'Item Type',
                },
            ],
        },
    },
};

let activityInit = {
    mode: 'activity_list',
    config: {
        activity_edit: {
            back: false,
        },
        item_edit: {
            tags_on_create: [
                {
                    type: 'Product',
                    name: 'Galileo Assessment',
                },
            ],
        },
        global: {
            hide_tags: [
                {
                    type: 'Article',
                },
                {
                    type: 'Unit',
                },
                {
                    type: 'Grade',
                },
                {
                    type: 'Item DOK',
                },
                {
                    type: 'Item Skill',
                },
                {
                    type: 'Item Type',
                },
            ],
        },
    },
};

export async function getItemsInit(itemReference, location, user, token) {
    let init;
    if (await learnosityPermissionsService.canEditItem(itemReference, user, token)) {
        init = itemsInit;
        init.config.item_edit.item.save = true;
        init.config.widget_templates.save = true;
    } else {
        init = itemsInit;
        init.config.item_edit.item.save = false;
        init.config.widget_templates.save = false;
    }
    init.mode = 'item_edit';
    init.domain = location;
    init.reference = itemReference;
    init.user = {
        email: user.email,
        firstname: user.given_name,
        lastname: user.family_name,
        id: user.email,
    };
    return init;
}

export async function getSettingsInit(itemReference, location, user, token) {
    let init;
    if (await learnosityPermissionsService.canEditItem(itemReference, user, token)) {
        init = settingsInit;
        init.config.item_edit.item.save = true;
        init.config.widget_templates.save = true;
    } else {
        init = settingsInit;
        init.config.item_edit.item.save = false;
        init.config.widget_templates.save = false;
    }
    init.mode = 'item_edit';
    init.reference = itemReference;
    init.domain = location;
    init.user = {
        email: user.email,
        firstname: user.given_name,
        lastname: user.family_name,
        id: user.email,
    };
    return init;
}

export function getActivityListInit(location, user) {
    activityInit.mode = 'activity_list';
    activityInit.domain = location;
    activityInit.user = {
        email: user.email,
        firstname: user.given_name,
        lastname: user.family_name,
        id: user.email,
    };
    return activityInit;
}

export function getItemsListInit(location, user) {
    itemsInit.mode = 'item_list';
    itemsInit.domain = location;
    itemsInit.user = {
        email: user.email,
        firstname: user.given_name,
        lastname: user.family_name,
        id: user.email,
    };
    return itemsInit;
}
