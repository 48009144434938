import * as Yup from 'yup';
import { parseStringToArrayEntries } from '../formParsingUtils';
import { integerTest, numericTest, numericMinTest, arrayBlankEntryTest, requiredTest, numericMaxTest } from './utils/customValidationTests';

// stage behavior
const STAGE_MIN = 1;
const STAGE_MAX = 3;
const UPPERBOUNDTHETAGRADE_MIN = -1;
const UPPERBOUNDTHETAGRADE_MAX = 13;

// bundle fields
const ITEMMIN = 1;
const MAXPERCENTPOLYITEMS_MIN = 0;
const MAXPERCENTPOLYITEMS_MAX = 15;

// bundle field test fields
const ITEMLIMIT_MIN = 1;
const BUNDLE_MAX_ITEMS_MIN = 0;

// testlet fields
const MAXTESTLETS_MIN = 1;

const baseCastFields = Yup.object().shape({
	testType: Yup.string().required(),
	stage: Yup.number().integer().min(STAGE_MIN).max(STAGE_MAX),
	interstitialItemId: Yup.string().required(),
	chronologicalGradeLevels: Yup.array()
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (value) => value);
		})
		.of(Yup.string()),
	estimatedGradesToSkip: Yup.array()
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (value) => value);
		})
		.of(Yup.string()),
	upperBoundThetaGrade: Yup.number().integer().min(UPPERBOUNDTHETAGRADE_MIN).max(UPPERBOUNDTHETAGRADE_MAX),
});

const bundleCastFields = Yup.object().shape({
	itemMin: Yup.number().integer().min(ITEMMIN).required(),
	itemMax: Yup.number().integer().min(ITEMMIN).required(),
	maxPercentPolyItems: Yup.number().integer().min(MAXPERCENTPOLYITEMS_MIN).max(MAXPERCENTPOLYITEMS_MAX).required(),
	overallStdErrTolerance: Yup.number().required(),
	domainStdErrTolerance: Yup.number().required(),
	useDomainsEstimatedFromStage: Yup.number().integer().required(),
	fieldTestOptions: Yup.object().shape({
		bundleActivityIds: Yup.array().transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (value) => value);
		}),
		bundleMaxItems: Yup.number().integer().required(),
	}),
});

const bundleFieldTestCastFields = Yup.object().shape({
	itemLimit: Yup.number().integer().min(ITEMLIMIT_MIN).required(),
	bundleActivityId: Yup.string().required(),
});

const testletCastFields = Yup.object().shape({
	maxTestlets: Yup.number().integer().min(MAXTESTLETS_MIN).required(),
});

const testletFieldTestCastFields = Yup.object().shape({
	maxTestlets: Yup.number().integer().min(MAXTESTLETS_MIN).required(),
	testletActivityIds: Yup.array()
		.of(Yup.string())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (value) => value);
		})
		.required(),
});

export const bundleCastSchema = baseCastFields.concat(bundleCastFields);
export const bundleFieldTestCastSchema = baseCastFields.concat(bundleFieldTestCastFields);
export const testletCastSchema = baseCastFields.concat(testletCastFields);
export const testletFieldTestCastSchema = baseCastFields.concat(testletFieldTestCastFields);

const inactiveFieldsValidationFields = Yup.object().shape({
	testType: Yup.string().test(requiredTest),
	stage: Yup.string().test(requiredTest).test(integerTest).test(numericMinTest(STAGE_MIN)).test(numericMaxTest(STAGE_MAX)),
	interstitialItemId: Yup.string().test(requiredTest),
	chronologicalGradeLevels: Yup.string().test(arrayBlankEntryTest),
	estimatedGradesToSkip: Yup.string().test(arrayBlankEntryTest),
	upperBoundThetaGrade: Yup.string()
		.test(numericTest)
		.test(integerTest)
		.test(numericMinTest(UPPERBOUNDTHETAGRADE_MIN))
		.test(numericMaxTest(UPPERBOUNDTHETAGRADE_MAX)),

	// bundle-specific fields.
	itemMin: Yup.string(),
	itemMax: Yup.string(),
	maxPercentPolyItems: Yup.string(),
	overallStdErrTolerance: Yup.string(),
	domainStdErrTolerance: Yup.string(),
	useDomainsEstimatedFromStage: Yup.string(),
	fieldTestOptions: Yup.object().shape({
		bundleActivityIds: Yup.string(),
		bundleMaxItems: Yup.string(),
	}),

	// bundle-field-test-specific fields
	itemLimit: Yup.string(),
	bundleActivityId: Yup.string(),

	// testlet and testletFieldTest specific fields
	maxTestlets: Yup.string(),

	// testletFieldTest specific fields
	testletActivityIds: Yup.string(),
});

export const bundleValidationSchema = inactiveFieldsValidationFields.shape({
	itemMin: Yup.string().test(requiredTest).test(numericTest).test(integerTest).test(numericMinTest(ITEMMIN)),
	itemMax: Yup.string().test(requiredTest).test(numericTest).test(integerTest).test(numericMinTest(ITEMMIN)),
	maxPercentPolyItems: Yup.string()
		.test(requiredTest)
		.test(numericTest)
		.test(integerTest)
		.test(numericMinTest(MAXPERCENTPOLYITEMS_MIN))
		.test(numericMaxTest(MAXPERCENTPOLYITEMS_MAX)),
	overallStdErrTolerance: Yup.string().test(requiredTest).test(numericTest),
	domainStdErrTolerance: Yup.string().test(requiredTest).test(numericTest),
	useDomainsEstimatedFromStage: Yup.string()
		.test(requiredTest)
		.test(numericTest)
		.test(integerTest)
		.test(numericMinTest(STAGE_MIN - 1))
		.test(numericMaxTest(STAGE_MAX - 1)),
	fieldTestOptions: Yup.object().shape({
		bundleActivityIds: Yup.string().test(arrayBlankEntryTest),
		bundleMaxItems: Yup.string().test(numericTest).test(integerTest).test(numericMinTest(BUNDLE_MAX_ITEMS_MIN)),
	}),
});

export const bundleFieldTestValidationSchema = inactiveFieldsValidationFields.shape({
	itemLimit: Yup.string().test(requiredTest).test(numericTest).test(integerTest).test(numericMinTest(ITEMLIMIT_MIN)),
	bundleActivityId: Yup.string().test(requiredTest),
});

export const testletValidationSchema = inactiveFieldsValidationFields.shape({
	maxTestlets: Yup.string().test(requiredTest).test(numericTest).test(integerTest).test(numericMinTest(MAXTESTLETS_MIN)),
});

export const testletFieldTestValidationSchema = inactiveFieldsValidationFields.shape({
	maxTestlets: Yup.string().test(requiredTest).test(numericTest).test(integerTest).test(numericMinTest(MAXTESTLETS_MIN)),
	testletActivityIds: Yup.string().test(requiredTest).test(arrayBlankEntryTest),
});
