import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Learnosity from '../../components/Learnosity/Learnosity';
import { useOktaAuth } from '@okta/okta-react';
import * as initService from '../../services/InitService';
import ReactDOM from 'react-dom';
import PreviewButtons from '../../components/PreviewButtons/PreviewButtons';

export default function ItemsSearchContainer() {
	const { authState, authService } = useOktaAuth();
	const [userInfo, setUserInfo] = useState(null);
	let [learnosityInit, setLearnosityInit] = useState(null);
	let [authorApp, setAuthorApp] = useState(undefined);
	let { searchQuery } = useParams();
	let history = useHistory();
	let [currentQuery] = useState(searchQuery);

	useEffect(() => {
		if (!authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setUserInfo(null);
		} else {
			authService.getUser().then((info) => {
				setUserInfo(info);
			});
		}
	}, [authState, authService]); // Update if authState changes

	useEffect(() => {
		if (userInfo) {
			setLearnosityInit(initService.getItemsListInit(window.location.hostname, userInfo));
		}
	}, [userInfo]); // Update if authState changes

	function onItemListRendered(event) {
		authorApp.navigate(`items/search/${searchQuery}`);
		const itemListHeaders = document.querySelectorAll('.lrn-item-list .lrn-list-view .lrn-list-view-heading-wrapper:first-child');
		itemListHeaders.forEach((element) => {
			const itemReference = encodeURIComponent(element.querySelector('a')?.innerText);
			const reactSubComponent = document.createElement('div');
			element.prepend(reactSubComponent);
			ReactDOM.render(
				<React.StrictMode>
					<PreviewButtons itemId={itemReference} />
				</React.StrictMode>,
				reactSubComponent
			);
		});
	}

	function getAuthorApp(authorApp) {
		setAuthorApp(authorApp);
	}

	function onNavigate(event) {
		console.log(event);
		// override new items button
		if (event.data.route === 'items/new') {
			event.preventDefault();
			history.push('/templates');
		}
		// // this is used to put the item id in the url
		if (event.data.route === 'items/:reference') {
			event.preventDefault();
			let itemId = event.data.location.replace('/item', '');
			history.push(`/${itemId}`);
		}

		if (event.data.route === 'items') {
			event.preventDefault();
			history.push(`/items`);
		}
		//inception
		if (event.data.route === 'items/search/:query') {
			let query = event.data.location.replace('items/search/', '');
			if (query !== currentQuery) history.push(`/items/search/${query}`);
		}
	}
	return (
		<>
			<Learnosity init={learnosityInit} navigate={onNavigate} getAuthorApp={getAuthorApp} itemListRendered={onItemListRendered} />
		</>
	);
}
