import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import PassageItemMap from '../../components/PassageItemMap/PassageItemMap';
import * as learnosityApi from '../../api/imagineLearnosityApi';
import { useOktaAuth } from '@okta/okta-react';
import Spinner from 'react-bootstrap/Spinner';

function PassageContainer() {
	const { authState } = useOktaAuth();
	let [passageItemMaps, setPassageMaps] = useState([]);

	useEffect(() => {
		if (authState.isAuthenticated) {
			learnosityApi.getPassageItemMaps({ Product: 'Galileo Assessment' }, authState.accessToken).then((_passageItemMaps) => {
				setPassageMaps(_passageItemMaps);
			});
		}
	}, [authState]);

	if (passageItemMaps.length === 0) {
		return (
			<Container>
				<h1>Passages</h1>
				<Spinner animation="grow" variant="primary" />
				<Spinner animation="grow" variant="secondary" />
				<Spinner animation="grow" variant="success" />
				<Spinner animation="grow" variant="danger" />
				<Spinner animation="grow" variant="warning" />
				<Spinner animation="grow" variant="info" />
				<Spinner animation="grow" variant="dark" />
			</Container>
		);
	} else {
		return (
			<Container>
				<h1>Passages</h1>
				{passageItemMaps.map((passageItemMap) => (
					<PassageItemMap key={passageItemMap.passage.reference} passageItemMap={passageItemMap} />
				))}
			</Container>
		);
	}
}

export default PassageContainer;
