import React from 'react';
import TextInput from './common/TextInput';
import BasicSettingOptions from '../../types/catConfig/configForm/basicSettings';
import { Accordion, Card } from 'react-bootstrap';

interface BasicSettingsProps {
	handleChange: (event: React.ChangeEvent) => void;
	basicSettings: BasicSettingOptions;
}

const BasicSettings = ({
	handleChange,
	basicSettings: BasicSettingOptions,
	basicSettings: { Subject, ItemPoolId, SchoolYearStartMonth, SchoolYearStartDay },
}: BasicSettingsProps) => {
	return (
		<Accordion>
			<Card>
				<Accordion.Toggle as={Card.Header} eventKey="0">
					Item Settings
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<div className="container-fluid">
							<div className="row">
								<div className="col">
									<TextInput
										name="Subject"
										label="Subject"
										onChange={handleChange}
										placeholder="Subject"
										value={Subject}
										error=""
									/>
								</div>
								<div className="col">
									<TextInput
										name="ItemPoolId"
										label="Item Pool Id"
										onChange={handleChange}
										placeholder="ItemPoolId"
										value={ItemPoolId}
										error=""
									/>
								</div>
								<div className="col">
									<TextInput
										name="SchoolYearStartMonth"
										label="School Year Start Month"
										onChange={handleChange}
										placeholder="School Year Start Month"
										value={SchoolYearStartMonth}
										error=""
									/>
								</div>
								<div className="col">
									<TextInput
										name="SchoolYearStartDay"
										label="School Year Start Day"
										onChange={handleChange}
										placeholder="School Year Start Day"
										value={SchoolYearStartDay}
										error=""
									/>
								</div>
							</div>
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export default BasicSettings;
