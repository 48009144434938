import React from 'react';
import { ARRAY_ENTRY_KEY } from '../../../containers/catConfig/validationRules/locale';
import { ErrorReport } from '../../../types/catConfig/errorReport';

interface ErrorProps {
	error: ErrorReport | string;
}

const Errors = ({ error }: ErrorProps, ...props: any) => {
	if (!error) {
		return null;
	}
	const fieldErrors = Object.entries(error).filter(([k, v]) => k !== ARRAY_ENTRY_KEY);
	// if arrayErrors exists as a property in the ErrorReport, then the value of that property will be a nested ErrorReport object.
	const arrayErrors = typeof error === 'object' ? (error.arrayEntry ? Object.entries(error.arrayEntry) : []) : [];

	return (
		<>
			{typeof error === 'object' && Object.keys(error).length > 0 ? (
				<div className="alert alert-secondary">
					<div className="row">
						{fieldErrors.length > 0 ? (
							<div className="col">
								<div className="row" key="mustLabel">
									<div className="col" style={{ fontSize: 'small' }}>
										Must:
									</div>
								</div>
								{fieldErrors.map(([k, v]) => {
									const style = v ? { color: 'red', fontSize: 'small' } : { color: 'green', fontSize: 'small' };
									const message = v ? `\u2a2f ${k}` : `\u2713 ${k}`;
									return (
										<div className="row" key={k}>
											<div className="col" style={style}>
												{message}
											</div>
										</div>
									);
								})}
							</div>
						) : null}
						{arrayErrors.length > 0 ? (
							<div className="col">
								<div className="row" key="ListMustLabel">
									<div className="col" style={{ fontSize: 'small' }}>
										List Entries Must:
									</div>
								</div>
								{arrayErrors.map(([k, v]) => {
									const style = v ? { color: 'red', fontSize: 'small' } : { color: 'green', fontSize: 'small' };
									const message = v ? `\u2a2f ${k}` : `\u2713 ${k}`;
									return (
										<div className="row" key={k}>
											<div className="col" style={style}>
												{message}
											</div>
										</div>
									);
								})}
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</>
	);
};

export default Errors;
