export const ARRAY_ENTRY_KEY = 'arrayEntry';
const REQUIRED_FIELD_MESSAGE = 'Not be blank';
const NUMERIC_MESSAGE = 'Be numeric';
const INTEGER_MESSAGE = 'Be an integer';
const ALPHANUMERIC_MESSAGE = 'Have at least one alphanumeric character';
const numericMinMessage = (min: number) => `Be at least ${min}`;
const numericMaxMessage = (max: number) => `Be at most ${max}`;
// All error messages must complete the sentence "this value must..."

export interface CustomLocale {
	[key: string]: { [key: string]: string | ((val: number) => string) | undefined } | undefined;
	mixed?: {
		[key: string]: string | ((val: number) => string) | undefined;
		required: string;
		default: undefined;
		notType: undefined;
	};
	string?: {
		[key: string]: string | ((val: number) => string) | undefined;
		default: undefined;
		required: string;
		min: (val: number) => string;
		max: (val: number) => string;
		alphaNumericRequired: string;
		numeric: string;
		integer: string;
		numMin: (val: number) => string;
		numMax: (val: number) => string;
		arrayEntryEmpty: string;
		arrayEntryAlphaNumericRequired: string;
		arrayEntryNumeric: string;
		arrayEntryInteger: string;
		arrayEntryNumMin: (val: number) => string;
		arrayEntryNumMax: (val: number) => string;
		numericRelativeMin: (val: number) => string;
		lessThanVerticalScale: string;
	};
}

const locale: CustomLocale = {
	mixed: {
		default: undefined,
		required: REQUIRED_FIELD_MESSAGE,
		notType: undefined,
	},
	string: {
		default: undefined,
		required: REQUIRED_FIELD_MESSAGE,
		min: (min) => `Have a length of at least ${min} characters`,
		max: (max) => `Have a length of at most ${max} characters`,
		alphaNumericRequired: ALPHANUMERIC_MESSAGE,
		numeric: NUMERIC_MESSAGE,
		integer: INTEGER_MESSAGE,
		numMin: numericMinMessage,
		numMax: numericMaxMessage,
		arrayEntryEmpty: REQUIRED_FIELD_MESSAGE,
		arrayEntryAlphaNumericRequired: ALPHANUMERIC_MESSAGE,
		arrayEntryNumeric: NUMERIC_MESSAGE,
		arrayEntryInteger: INTEGER_MESSAGE,
		arrayEntryNumMin: numericMinMessage,
		arrayEntryNumMax: numericMaxMessage,
		numericRelativeMin: numericMinMessage,
		lessThanVerticalScale: 'Be within the range (-Vertical Scale Limit, Vertical Scale Limit)',
	},
};

export default locale;
