import * as Yup from 'yup';
import locale, { ARRAY_ENTRY_KEY } from '../locale';

/**
 * These are custom yup tests. They are necessary to test numeric and array fields in the form
 * because the form data are all strings. These functions validate numeric and array data without
 * casting the form state from strings.
 * They should return false if there is a validation error in the input error and otherwise return true.
 */

export const requiredTest: Yup.TestConfig = {
	name: 'required',
	test: (value) => {
		if (typeof value !== 'string') return false;
		return value.trim().length > 0;
	},
	message: locale.string?.required,
};

export const numericTest: Yup.TestConfig = {
	name: 'numeric',
	test: (value) => {
		if (typeof value !== 'string') return false;
		if (value.length === 0) return true;
		if (value.trim().length === 0) return false;
		return detectIsNumeric(value);
	},
	message: locale.string!.numeric,
};

export const integerTest: Yup.TestConfig = {
	name: 'integer',
	test: (value) => {
		if (typeof value !== 'string') return false;
		if (value.length === 0) return true;
		if (value.trim().length === 0) return false;
		return detectIsNumeric(value) && detectInteger(value);
	},
	message: locale.string!.integer,
};

export const numericRelativeMinTest = (min: number): Yup.TestConfig => {
	return {
		name: 'numericRelativeMin',
		test: function (value: any, context: any) {
			if (typeof value !== 'string') return false;
			if (value.length === 0) return true;
			if (value.trim().length === 0) return false;
			return true;
		},
		message: locale.string!.numMin(min),
		params: { min },
	};
};

export const numericMinTest = (min: number): Yup.TestConfig => {
	return {
		name: 'numMin',
		test: (value) => {
			if (typeof value !== 'string') return false;
			if (value.length === 0) return true;
			if (value.trim().length === 0) return false;
			// true if blank because the only test that should fail on a blank value is the required() test.
			if (value.length === 0) return true;
			return Yup.number().min(min).notRequired().isValidSync(value);
		},
		message: locale.string!.numMin(min),
		params: { min },
	};
};

export const numericMaxTest = (max: number): Yup.TestConfig => {
	return {
		name: 'numMax',
		test: (value) => {
			if (typeof value !== 'string') return false;
			if (value.length === 0) return true;
			if (value.trim().length === 0) return false;
			// true if blank because the only test that should fail on a blank value is the required() test.
			if (value.length === 0) return true;
			return Yup.number().max(max).notRequired().isValidSync(value);
		},
		message: locale.string!.numMax(max),
		params: { max },
	};
};

export const arrayBlankEntryTest: Yup.TestConfig = {
	name: 'arrayEntryEmpty',
	test: (value) => {
		if (typeof value !== 'string') return false;
		if (value.length === 0) return true;
		const arrayEntries = parseArrayFromCSVString(value);
		if (arrayEntries.length === 1) {
			return true;
		}
		return !arrayEntries.some((entry, index) => entry.trim().length === 0);
	},
	message: locale.string!.arrayEntryEmpty,
	params: { [ARRAY_ENTRY_KEY]: true },
};

export const arrayEntryNumericTest: Yup.TestConfig = {
	name: 'arrayEntryNumeric',
	test: (value) => {
		if (typeof value !== 'string') return false;
		if (value.length === 0) return true;
		return parseArrayFromCSVString(value).filter((entry) => !detectIsNumeric(entry)).length === 0;
	},
	message: locale.string!.arrayEntryNumeric,
	params: { [ARRAY_ENTRY_KEY]: true },
};

export const arrayEntryIntegerTest: Yup.TestConfig = {
	name: 'arrayEntryInteger',
	test: (value) => {
		if (typeof value !== 'string') return false;
		if (value.length === 0) return true;
		return parseArrayFromCSVString(value).filter((entry) => !detectIsNumeric(entry) || !detectInteger(entry)).length === 0;
	},
	message: locale.string!.arrayEntryInteger,
	params: { [ARRAY_ENTRY_KEY]: true },
};

export const PRECISION_RANGE_ERROR_MESSAGE = 'precision must be at least 0';
export const STRING_EXPECTED_ERROR = 'This function expects a string';
// detect validation errors from user input and return user-facing descriptive error messages.
export function detectNumericPrecisionWithinLimit(value: string, precision: number): boolean {
	if (precision < 0) {
		throw new RangeError(PRECISION_RANGE_ERROR_MESSAGE);
	}
	const decimalPosition = value.indexOf('.');
	if (decimalPosition === -1) {
		return true;
	}
	if (value.length - (decimalPosition + 1) > precision) {
		return false;
	}
	return true;
}

export function detectInteger(value: string): boolean {
	return !value.includes('.');
}

export function detectIsNumeric(value: string): boolean {
	/**
	 * This regex should represent
	 * 0 or more digits (0 | 123 | ) OR
	 * a negative sign and 1 or more digits (-0 | -123) OR
	 * a negative sign or not, 0 or more digits, a decimal point, and one or more digits (-.12 | -0.1 | .02 | 1.2 ...).
	 * so it should match on all possible numeric values
	 */
	const numericPattern = /^((\d*)|(-\d+)|(-?\d*\.\d*))$/;
	return numericPattern.test(value);
}

export function detectAlphaNumeric(value: string): boolean {
	const atLeastOneAlphaNumeric = /[a-zA-Z0-9]+/i;
	return atLeastOneAlphaNumeric.test(value);
}

export function parseArrayFromCSVString(value: string): string[] {
	return value.split(',').map((entry) => entry.trim());
}
