import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import NumberInput from './common/NumberInput';
import StatisticalParameters from '../../types/catConfig/configForm/statisticalParameters';
import SelectInput from './common/SelectInput';

interface StatsConfigProps {
	handleChange: (event: React.ChangeEvent) => void;
	statsConfig: StatisticalParameters;
	handleVerticalScaleLimitChange: (event: React.ChangeEvent) => void;
}

const D_VALUES = ['1', '1.7'];

const StatsConfig = ({
	handleChange,
	statsConfig,
	statsConfig: { D, VerticalScaleLimit, NewtonRaphsonAdjustmentLimit, PopulationMean, BayesModalPopVar, MaxLikelihoodPopVar },
	handleVerticalScaleLimitChange,
}: StatsConfigProps) => {
	const [editMode, setEditMode] = useState(false);
	function handleEditClick() {
		setEditMode(true);
	}
	function handleSaveClick() {
		setEditMode(false);
	}
	function handleCancelClick() {
		setEditMode(false);
	}
	return (
		<Accordion>
			<Card>
				<Accordion.Toggle as={Card.Header} eventKey="0">
					<div className="row">
						<div className="col">Statistical Parameters</div>
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<div className="container-fluid">
							{!editMode ? (
								<div className="row">
									<div className="col-1">
										<div className="btn btn-primary" onClick={handleEditClick}>
											Edit
										</div>
									</div>
								</div>
							) : (
								<div className="row">
									<div className="col-1">
										<div className="btn btn-primary" onClick={handleSaveClick}>
											Save Section
										</div>
									</div>
									<div className="col-1">
										<div className="btn btn-primary" onClick={handleCancelClick}>
											Cancel Section
										</div>
									</div>
								</div>
							)}
							{editMode ? (
								<>
									<div className="row">
										<div className="col">
											<SelectInput
												name="D"
												options={D_VALUES}
												label="D"
												onChange={handleChange}
												placeholder="D"
												value={D}
												error=""
											/>
										</div>
										<div className="col">
											<NumberInput
												name="PopulationMean"
												step={0.1}
												label="PopulationMean"
												onChange={handleChange}
												placeholder="PopulationMean"
												value={PopulationMean}
												error=""
											/>
										</div>
										<div className="col">
											<NumberInput
												name="VerticalScaleLimit"
												step={0.1}
												label="VerticalScaleLimit"
												onChange={handleVerticalScaleLimitChange}
												placeholder="VerticalScaleLimit"
												value={VerticalScaleLimit}
												error=""
											/>
										</div>
									</div>
									<div className="row">
										<div className="col">
											<NumberInput
												name="BayesModalPopVar"
												step={0.1}
												label="BayesModalPopVar"
												onChange={handleChange}
												placeholder="BayesModalPopVar"
												value={BayesModalPopVar}
												error=""
											/>
										</div>
										<div className="col">
											<NumberInput
												name="NewtonRaphsonAdjustmentLimit"
												step={0.1}
												label="NewtonRaphsonAdjustmentLimit"
												onChange={handleChange}
												placeholder="NewtonRaphsonAdjustmentLimit"
												value={NewtonRaphsonAdjustmentLimit}
												error=""
											/>
										</div>
										<div className="col">
											<NumberInput
												name="MaxLikelihoodPopVar"
												step={0.1}
												label="MaxLikelihoodPopVar"
												onChange={handleChange}
												placeholder="MaxLikelihoodPopVar"
												value={MaxLikelihoodPopVar}
												error=""
											/>
										</div>
									</div>
								</>
							) : (
								<>
									<div className="row">
										<div className="col">D: {D}</div>
										<div className="col">Population Mean: {PopulationMean}</div>
										<div className="col">Vertical Scale Limit: {VerticalScaleLimit}</div>
									</div>
									<div className="row">
										<div className="col">Bayes Modal Pop Var: {BayesModalPopVar}</div>
										<div className="col">NewtonRaphsonAdjustmentLimit: {NewtonRaphsonAdjustmentLimit}</div>
										<div className="col">Max Likelihood Pop Var: {MaxLikelihoodPopVar}</div>
									</div>
								</>
							)}
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export default StatsConfig;
