import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './PassageItemMap.scss';
import DOMPurify from 'dompurify';

export default function PassageItemMap({ passageItemMap }) {
	const colStyle = {
		marginLeft: '10px',
	};

	return (
		<Row className="border border-dark passage-box">
			<Col xs={8} style={colStyle}>
				<Row>
					<h5>{passageItemMap.passage.heading}</h5>
				</Row>
				<Row>
					<div
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(passageItemMap.passage.content),
						}}
					/>
				</Row>
			</Col>
			<Col style={colStyle}>
				<Row>
					<h5>Passage Identifier:</h5>
					<p>{passageItemMap.passage.reference}</p>
				</Row>
				<Row>
					<h5>Items:</h5>
				</Row>
				{passageItemMap.items.map((item) => (
					<Row key={item}>
						<Link to={`items/${item}`}>{item} </Link>
					</Row>
				))}
				<Row>
					<h5>Metadata:</h5>
				</Row>
				<Row>
					<p>{JSON.stringify(passageItemMap.passage.metadata)}</p>
				</Row>
			</Col>
		</Row>
	);
}
