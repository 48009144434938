import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Learnosity from '../../components/Learnosity/Learnosity';
import { useParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import * as initService from '../../services/InitService';
import PreviewButtons from '../../components/PreviewButtons/PreviewButtons';

function ItemListContainer() {
	const { authState, authService } = useOktaAuth();
	const [userInfo, setUserInfo] = useState(null);
	let [learnosityInit, setLearnosityInit] = useState(null);
	let [authorApp, setAuthorApp] = useState(undefined);
	let history = useHistory();
	let params = useParams();

	useEffect(() => {
		if (!authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setUserInfo(null);
		} else {
			authService.getUser().then((info) => {
				setUserInfo(info);
			});
		}
	}, [authState, authService]); // Update if authState changes

	useEffect(() => {
		if (userInfo) {
			setLearnosityInit(initService.getItemsListInit(window.location.hostname, userInfo));
		}
	}, [userInfo]); // Update if authState changes

	function onNavigate(event) {
		console.log(event);
		// override new items button
		if (event.data.route === 'items/new') {
			event.preventDefault();
			history.push('/templates');
		}
		// // this is used to put the item id in the url
		if (event.data.route === 'items/:reference') {
			event.preventDefault();
			let itemId = event.data.location.replace('/item', '');
			history.push(itemId);
		}

		if (event.data.route === 'items/search/:query') {
			event.preventDefault();
			let query = event.data.location.replace('items/search/', '');
			history.push(`/items/search/${query}`);
		}
	}

	function onItemListRendered(event) {
		if (authorApp && !params.itemReference) {
			const itemListHeaders = document.querySelectorAll('.lrn-item-list .lrn-list-view .lrn-list-view-heading-wrapper:first-child');
			itemListHeaders.forEach((element) => {
				const itemReference = encodeURIComponent(element.querySelector('a')?.innerText);
				const reactSubComponent = document.createElement('div');
				element.prepend(reactSubComponent);
				ReactDOM.render(
					<React.StrictMode>
						<PreviewButtons itemId={itemReference} />
					</React.StrictMode>,
					reactSubComponent
				);
			});
		}
	}

	function getAuthorApp(authorApp) {
		setAuthorApp(authorApp);
	}

	return (
		<>
			<Learnosity init={learnosityInit} navigate={onNavigate} getAuthorApp={getAuthorApp} itemListRendered={onItemListRendered} />
		</>
	);
}

export default ItemListContainer;
