import React from 'react';
//import CatNip from "./catnip.jpg";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Link } from 'react-router-dom';

function HomeContainer() {
	return (
		<Container>
			<Jumbotron fluid>
				<Container>
					<h1>CATnip</h1>
					<p>The home to all of your CAT authoring needs.</p>
				</Container>
			</Jumbotron>
			<Col>
				<Row>
					<Card style={{ width: '18rem' }}>
						<Card.Body>
							<Card.Title>Items</Card.Title>
							<Card.Text>Create or update items.</Card.Text>
							<Link to="/items" className="card-link">
								Items
							</Link>
						</Card.Body>
					</Card>

					<Card style={{ width: '18rem' }}>
						<Card.Body>
							<Card.Title>Activities</Card.Title>
							<Card.Text>Create or update activities.</Card.Text>
							<Link to="/Activities" className="card-link">
								Activities
							</Link>
						</Card.Body>
					</Card>

					<Card style={{ width: '18rem' }}>
						<Card.Body>
							<Card.Title>Passages</Card.Title>
							<Card.Text>View items associated with passages.</Card.Text>
							<Link to="/passages" className="card-link">
								Passages
							</Link>
						</Card.Body>
					</Card>
				</Row>
			</Col>
		</Container>
	);
}

export default HomeContainer;
