import React from 'react';
import BasicSettings from './BasicSettings';
import SimulationSettings from './SimulationSettings';
import ScaledScoreConfig from './ScaledScoreConfig';
import StatsConfig from './StatsConfig';
import StageBehaviors from './StageBehaviors';
import GradeLevelDomains from './GradeLevelDomains';
import BasicSettingOptions from '../../types/catConfig/configForm/basicSettings';
import SimulationOptions, { SimulationSettingsErrors } from '../../types/catConfig/configForm/simulationSettings';
import StatisticalParameters from '../../types/catConfig/configForm/statisticalParameters';
import FormStageBehaviorOptions from '../../types/catConfig/configForm/stageBehaviorOptions';
import GradeLevelDomainEntries from '../../types/catConfig/configForm/gradeLevelDomains';
import { ErrorReport } from '../../types/catConfig/errorReport';
import { formFormats } from '../../containers/catConfig/CatConfigContainer';
import scaledScoreLowerBoundaries from '../../types/catConfig/configForm/scaledScoreLowerBoundaries';

interface ConfigFormProps {
	handleBasicSettingsChange: (event: React.ChangeEvent) => void;
	basicSettings: BasicSettingOptions;
	handleSimulationSettingsChange: (event: React.ChangeEvent) => void;
	simulationSettings: SimulationOptions;
	simulationSettingsErrors: SimulationSettingsErrors;
	handleGradeLevelDomainChange: (event: React.ChangeEvent) => void;
	gradeLevelDomains: GradeLevelDomainEntries;
	handleStatsConfigChange: (event: React.ChangeEvent) => void;
	statsConfig: StatisticalParameters;
	handleScaledScoreConfigChange: (event: React.ChangeEvent) => void;
	scaledScoreConfig: scaledScoreLowerBoundaries;
	scaledScoreConfigErrors: ErrorReport;
	handleThetaAndScaledScoreTableChange: (event: React.ChangeEvent, key: string) => void;
	handleStageBehaviorsChange: (event: React.ChangeEvent, key: number) => void;
	stageBehaviors: FormStageBehaviorOptions[];
	stageBehaviorErrors: ErrorReport[];
	addStageBehaviorEntry: () => void;
	deleteStageBehaviorEntry: (entryKey: number) => void;
	formFormat: formFormats;
}

const ConfigForm = ({
	handleBasicSettingsChange,
	basicSettings,
	handleSimulationSettingsChange,
	simulationSettings,
	simulationSettingsErrors,
	handleGradeLevelDomainChange,
	gradeLevelDomains,
	handleStatsConfigChange,
	statsConfig,
	handleScaledScoreConfigChange,
	scaledScoreConfig,
	scaledScoreConfigErrors,
	handleThetaAndScaledScoreTableChange,
	handleStageBehaviorsChange,
	stageBehaviors,
	stageBehaviorErrors,
	addStageBehaviorEntry,
	deleteStageBehaviorEntry,
	formFormat,
}: ConfigFormProps) => {
	return (
		<form>
			<div className="container-fluid">
				{formFormat === formFormats.Production ? (
					<div className="row">
						<div className="col">
							<BasicSettings handleChange={handleBasicSettingsChange} basicSettings={basicSettings} />
						</div>
					</div>
				) : null}
				{formFormat === formFormats.MonteCarlo ? (
					<div className="row">
						<div className="col">
							<SimulationSettings
								handleChange={handleSimulationSettingsChange}
								simulationSettings={simulationSettings}
								errors={simulationSettingsErrors}
							/>
						</div>
					</div>
				) : null}
				{formFormat === formFormats.Production ? (
					<div className="row">
						<div className="col">
							<GradeLevelDomains handleChange={handleGradeLevelDomainChange} gradeLevelDomains={gradeLevelDomains} />
						</div>
					</div>
				) : null}
				<div className="row">
					<div className="col">
						<StatsConfig
							handleChange={handleStatsConfigChange}
							statsConfig={statsConfig}
							handleVerticalScaleLimitChange={handleScaledScoreConfigChange}
						/>
					</div>
				</div>
				{formFormat === formFormats.Production ? (
					<div className="row">
						<div className="col">
							<ScaledScoreConfig
								handleChange={handleScaledScoreConfigChange}
								scaledScoreConfig={scaledScoreConfig}
								scaledScoreConfigErrors={scaledScoreConfigErrors}
								handleTableChange={handleThetaAndScaledScoreTableChange}
							/>
						</div>
					</div>
				) : null}
				<div className="row">
					<div className="col">
						<StageBehaviors
							handleChange={handleStageBehaviorsChange}
							stageBehaviors={stageBehaviors}
							stageBehaviorErrors={stageBehaviorErrors}
							addEntry={addStageBehaviorEntry}
							deleteEntry={deleteStageBehaviorEntry}
						/>
					</div>
				</div>
			</div>
		</form>
	);
};

export default ConfigForm;
