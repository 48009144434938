import * as Yup from 'yup';
import { parseStringToArrayEntries } from '../formParsingUtils';

export const gradeLevelDomainsCastSchema = Yup.object().shape({
	PreK: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Kindergarten: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	First: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Second: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Third: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Fourth: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Fifth: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Sixth: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Seventh: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Eighth: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Ninth: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Tenth: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Eleventh: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
	Twelfth: Yup.array()
		.of(Yup.string().required())
		.transform(function (value, rawVal) {
			return parseStringToArrayEntries(rawVal, (val) => val);
		}),
});
