import React, { useState, useEffect } from 'react';
import Learnosity from '../../components/Learnosity/Learnosity';
import SideBar from '../../components/SideBar/SideBar';
import { useParams, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import * as initService from '../../services/InitService';
import getEnv from '../../envs';
import './ItemContainer.scss';
import * as learnosityApi from '../../api/imagineLearnosityApi';


function ItemContainer() {
	let params = useParams();
	let history = useHistory();
	const { authState, authService } = useOktaAuth();
	const [userInfo, setUserInfo] = useState(null);
	const [itemData, setItemData] = useState(null);
	let [learnosityInit, setLearnosityInit] = useState(null);
	let [isLoading, setIsLoading] = useState(true);
	let [authorApp, setAuthorApp] = useState(undefined);
	useEffect(() => {
		if (authState.isAuthenticated && userInfo && params.itemReference) {
			learnosityApi.getItemParameters({"ItemIds": [params.itemReference]}, authState.accessToken ).then((_passageItemMaps) => {
				setItemData(_passageItemMaps);
			}).catch(err => console.error(err));
		}
	}, [authState, userInfo, params.itemReference]);
	console.log("itemData=>>",itemData); // Showing the itemData in Console

	useEffect(() => {
		if (!authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setUserInfo(null);
		} else {
			authService.getUser().then((info) => {
				setUserInfo(info);
			});
		}
	}, [authState, authService]); // Update if authState changes

	useEffect(() => {
		if (userInfo && params.itemReference) {
			initService.getItemsInit(params.itemReference, window.location.hostname, userInfo, authState.accessToken).then((init) => {
				setLearnosityInit(init);
			});
		}
	}, [authState.accessToken, params.itemReference, userInfo]); // Update if authState changes

	function onSaveSuccess(event) {
		if (event.data.item.reference !== params.itemReference) {
			history.push(`/items/${event.data.item.reference}`);
		}
	}

	function onItemRendered() {
		if (authorApp && params.itemReference) {
			const itemReference = encodeURIComponent(params.itemReference);
			const toolbar = document.querySelector('.lrn-author-item-toolbar .lrn-author-item-toolbar-right ul');
			const kinderListItem = document.createElement('li');
			const upperGradesListItem = document.createElement('li');
			const kinderPreview = document.createElement('button');
			kinderPreview.classList =
				'lrn-author-title-menu-element lrn-author-toolbar-button lrn-author-btn-outline lrn-btn-icon-left lrn-btn-responsive lrn-btn-i-xs lrn-btn';
			kinderPreview.innerHTML = 'K';
			kinderPreview.onclick = () => {
				window.open(`${getEnv().styledPreviewUrl}?itemId=${itemReference}&itemStyle=kinder`, '_blank');
			};
			kinderListItem.append(kinderPreview);
			const upperGradesPreview = document.createElement('button');
			upperGradesPreview.classList =
				'lrn-author-title-menu-element lrn-author-toolbar-button lrn-author-btn-outline lrn-btn-icon-left lrn-btn-responsive lrn-btn-i-xs lrn-btn';
			upperGradesPreview.innerHTML = '2+';
			upperGradesPreview.onclick = () => {
				window.open(`${getEnv().styledPreviewUrl}?itemId=${itemReference}&itemStyle=2Plus`, '_blank');
			};
			upperGradesListItem.append(upperGradesPreview);
			toolbar.append(kinderListItem);
			toolbar.append(upperGradesListItem);
		}
	}

	function getAuthorApp(authorApp) {
		setAuthorApp(authorApp);
		setIsLoading(false);
	}

	function saveItem(itemTags, status, description, notes) {
		authorApp.setItemTags(itemTags);
		authorApp.setStatus(status);
		authorApp.setItemDescription(description);
		authorApp.setItemNotes(notes);
		authorApp.save();
	}

	return (
		<>
			<div className="flex divide-x divide-gray-400">
				<div className="w-2/3" id="item">
					<Learnosity
						init={learnosityInit}
						getAuthorApp={getAuthorApp}
						itemRendered={onItemRendered}
						saveSuccess={onSaveSuccess}
					/>
				</div>
				<div className="w-1/3">
					<SideBar itemData={itemData} isLoading={isLoading} itemReference={params.itemReference} saveItem={saveItem} />
				</div>
			</div>
		</>
	);
}

export default ItemContainer;
