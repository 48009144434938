import React, { useState } from 'react';

export default function WorkflowDropdown(props) {
	let [workflowState, setWorkflowState] = useState(props.workflowStatus || 'In Authoring');

	function WorkflowStateChanged(event) {
		setWorkflowState(event.target.value);
		props.valueChanged(event.target.value);
	}

	return (
		<div>
			<label className="lrn-form-label-name">Workflow</label>
			<div className="lrn-form-group">
				<div className="lrn-custom-select">
					<select value={workflowState} onChange={WorkflowStateChanged} className="lrn-form-control">
						<option value="In Authoring">In Authoring</option>
						<option value="Needs Review">Needs Review</option>
						<option value="In Review">In Review</option>
						<option value="Needs Modification">Needs Modification</option>
						<option value="Accepted">Accepted</option>
						<option value="Certified">Certified</option>
						<option value="Field Testing">Field Testing</option>
						<option value="Operational">Operational</option>
						<option value="Benched">Benched</option>
						<option value="Retired">Retired</option>
					</select>
				</div>
			</div>
		</div>
	);
}
