import React, { useState, useReducer, useMemo, useEffect } from 'react';
import { Container, Card, ButtonGroup, ToggleButton } from 'react-bootstrap';
import DownloadButton from '../../components/FileTools/DownloadButton';
import UploadFileForm from '../../components/FileTools/UploadFileForm';
import ConfigForm from '../../components/ConfigForm/ConfigForm';
import FormStageBehaviorOptions, { FormFieldTestOptions } from '../../types/catConfig/configForm/stageBehaviorOptions';
import { StageBehaviorOptions, ConfigOptions, FieldTestOptions } from '../../types/catConfig/config';
import {
	configInitialState,
	basicSettingsInitialState,
	simulationSettingsInitialState,
	gradeLevelDomainsInitialState,
	scaledScoreLowerBoundariesInitialState,
	statsConfigFormInitialState,
	thetaAndScaledScoreTableInitialState,
	stageBehaviorInitialState,
	stageBehaviorOptionInitialState,
} from './initialState';
import SimulationSettings, { SimulationSettingsErrors } from '../../types/catConfig/configForm/simulationSettings';
import StatisticalParameters from '../../types/catConfig/configForm/statisticalParameters';
import { simulationSettingsValidator } from './validationRules/simulationSettings';
import { scaledScoreLowerBoundariesValidator, stageBehaviorValidator, detectFormikHasError } from './validationRules/utils/schemaValidator';
import { useFormik } from 'formik';
import { castFormToMonteCarlo, castFormToProduction } from './formParsingUtils';
import { ErrorReport } from '../../types/catConfig/errorReport';

/**
 * All attributes that can't be loaded into form state with a simple value.toString() parse
 * chronologicalGradeLevels and estimatedGradesToSkip don't need to be here because their values are interpreted
 *  as an array to form form group values.
 */
const ARRAY_ATTRIBUTES = [
	'QuestionTypeFilter',
	'SimulateForGrades',
	'testletActivityIds',
	'chronologicalGradeLevels',
	'estimatedGradesToSkip',
];
const STAGE_BEHAVIOR_REQUIRED_FIELDS = ['stage', 'testType', 'interstitialItemId'];
export enum formFormats {
	MonteCarlo = 'montecarlo',
	Production = 'production',
}

const CatConfigContainer = () => {
	const [basicSettings, setBasicSettings] = useState(basicSettingsInitialState);
	const simulationSettings = useFormik({
		initialValues: simulationSettingsInitialState,
		validate: simulationSettingsValidator,
		validateOnMount: true,
		onSubmit: () => {},
	});
	const [gradeLevelDomains, setGradeLevelDomains] = useState(gradeLevelDomainsInitialState);
	const [statsConfig, setStatsConfig] = useState(statsConfigFormInitialState);
	const scaledScoreLowerBoundaries = useFormik({
		initialValues: scaledScoreLowerBoundariesInitialState,
		validate: scaledScoreLowerBoundariesValidator,
		validateOnMount: true,
		validateOnChange: true,
		onSubmit: () => {},
	});
	const stageBehaviors = useFormik({
		initialValues: stageBehaviorInitialState,
		validate: stageBehaviorValidator,
		validateOnMount: true,
		onSubmit: () => {},
	});
	// initial value of 100 to prevent collision with keys from loaded config file.
	const [stageBehaviorKeyCounter, setStageBehaviorKeyCounter] = useReducer((c) => c + 1, 100);

	/**
	 * This is a string instead of a boolean because the cat config file and systems that use it will be changed
	 * in the near future. Adding a new format and dynamically rendering the corresponding UI layout will leverage
	 * this state value.
	 */
	const [formFormat, setFormFormat] = useState(formFormats.MonteCarlo);

	const formStatus = useMemo(() => {
		// formFormat is undefined on some re-renders when the toggle buttons have been clicked.
		// I'm guessing this is due to the asynchronous nature of the stateSetter.
		if (formFormat == null) {
			return false;
		}
		const validationConstraints: { [key: string]: any[] } = {
			[formFormats.MonteCarlo]: [simulationSettings.errors, stageBehaviors.errors],
			[formFormats.Production]: [scaledScoreLowerBoundaries.errors, stageBehaviors.errors],
		};
		return validationConstraints[formFormat].some((formikErrors) => detectFormikHasError(formikErrors));
	}, [simulationSettings.errors, scaledScoreLowerBoundaries.errors, stageBehaviors.errors, formFormat]);

	function handleUploadFile(event: React.FormEvent) {
		event.preventDefault();

		let fileInputElement = (event.target as HTMLFormElement).elements.namedItem('file') as HTMLInputElement;

		let file = fileInputElement?.files?.[0];
		if (file == null) return;

		let fileReader = new FileReader();
		fileReader.readAsText(file);
		fileReader.onloadend = () => {
			if (fileReader.result == null) return;
			try {
				let fileContent = JSON.parse(fileReader.result.toString());
				loadStateFromFile(fileContent);
			} catch (e) {
				if (e instanceof SyntaxError) {
					console.log('File content is not valid JSON');
				}
			}
		};
	}
	function loadStateFromFile(config: ConfigOptions) {
		setBasicSettings(() => {
			return {
				Subject: config?.Subject ? config?.Subject : basicSettingsInitialState.Subject,
				ItemPoolId: config?.ItemPoolId ? config?.ItemPoolId : basicSettingsInitialState.ItemPoolId,
				SchoolYearStartMonth: config?.SchoolYearStartMonth?.toString() ?? basicSettingsInitialState.SchoolYearStartMonth,
				SchoolYearStartDay: config?.SchoolYearStartDay?.toString() ?? basicSettingsInitialState.SchoolYearStartDay,
			};
		});

		simulationSettings.setValues(() => {
			const updatedState = Object.fromEntries(
				Object.entries(simulationSettingsInitialState)
					.filter(([k, v]) => ARRAY_ATTRIBUTES.indexOf(k) === -1)
					.map(([k, v]) => {
						return config?.[k] ? [k, config?.[k].toString()] : [k, v];
					})
			) as SimulationSettings;
			updatedState.QuestionTypeFilter = config?.QuestionTypeFilter
				? config?.QuestionTypeFilter?.join(',')
				: simulationSettingsInitialState.QuestionTypeFilter;
			updatedState.SimulateForGrades = config?.SimulateForGrades
				? config?.SimulateForGrades?.join(',')
				: simulationSettingsInitialState.SimulateForGrades;
			return {
				...updatedState,
			};
		});

		setGradeLevelDomains(() => {
			if (!config?.GradeLevelDomains) {
				return gradeLevelDomainsInitialState;
			}
			const updatedState = Object.fromEntries(
				Object.entries(gradeLevelDomainsInitialState).map(([k, v]) => {
					return config.GradeLevelDomains?.[k] ? [k, config.GradeLevelDomains?.[k].join(',')] : [k, v];
				})
			);
			return updatedState;
		});

		setStatsConfig(() => {
			const updatedState = Object.fromEntries(
				Object.entries(statsConfigFormInitialState).map(([k, v]) => {
					return config?.StatsConfig?.[k] ? [k, config?.StatsConfig?.[k].toString()] : [k, v];
				})
			) as StatisticalParameters;
			return {
				...updatedState,
			};
		});

		scaledScoreLowerBoundaries.setValues(() => {
			const multiplier = config?.ScaledScoreConfig?.ThetaMultiplier ?? configInitialState.ScaledScoreConfig!.ThetaMultiplier;
			const verticalShift = config?.ScaledScoreConfig?.ThetaVerticalShift ?? configInitialState.ScaledScoreConfig!.ThetaVerticalShift;
			const verticalScaleLimit = config?.StatsConfig?.VerticalScaleLimit ?? configInitialState.StatsConfig.VerticalScaleLimit;
			const min = config?.ScaledScoreConfig?.Min ?? configInitialState.ScaledScoreConfig?.Min;
			const max = config?.ScaledScoreConfig?.Max ?? configInitialState.ScaledScoreConfig?.Max;
			const lowerBoundaries =
				config?.lowerThetaBoundaries != null
					? Object.entries(config.lowerThetaBoundaries!).map(([k, v]) => {
							return {
								Grade: k,
								Theta: v.toString(),
								ScaledScore: calculateScaledScore(v, multiplier, verticalShift).toString(),
							};
					  })
					: thetaAndScaledScoreTableInitialState;
			return {
				ThetaMultiplier: multiplier.toString(),
				ThetaVerticalShift: verticalShift.toString(),
				VerticalScaleLimit: verticalScaleLimit.toString(),
				Min: min!.toString(),
				Max: max!.toString(),
				lowerBoundaries: lowerBoundaries,
			};
		});

		stageBehaviors.setValues(() => {
			const fileStageBehaviors = config.stageBehaviorOptions ?? config.StageBehaviorOptions;
			return fileStageBehaviors!.map((entry: StageBehaviorOptions, index: number) => {
				const optionalEntries = Object.fromEntries(
					Object.entries(entry).filter(([k, v]) => {
						// Get all items except those with keys in the array below.
						return [...STAGE_BEHAVIOR_REQUIRED_FIELDS, ...ARRAY_ATTRIBUTES].indexOf(k) === -1;
					})
				);

				let formEntry: FormStageBehaviorOptions = {
					...stageBehaviorOptionInitialState,
					key: index,
					stage: entry.stage.toString(),
					testType: entry.testType,
					interstitialItemId: entry.interstitialItemId,
					...Object.fromEntries(
						Object.entries(optionalEntries).map(([k, v]) => {
							return [k, v.toString()];
						})
					),
				};
				return {
					...formEntry,
					chronologicalGradeLevels:
						entry?.chronologicalGradeLevels?.join(',') ?? stageBehaviorOptionInitialState.chronologicalGradeLevels,
					estimatedGradesToSkip: entry?.estimatedGradesToSkip?.join(',') ?? stageBehaviorOptionInitialState.estimatedGradesToSkip,
					testletActivityIds: entry?.testletActivityIds?.join(',') ?? stageBehaviorOptionInitialState.testletActivityIds,
					fieldTestOptions: loadFieldTestOptions(entry?.fieldTestOptions),
				} as FormStageBehaviorOptions;
			});
		});
	}

	function loadFieldTestOptions(fieldTestOptions: FieldTestOptions | undefined): FormFieldTestOptions | object {
		return {
			bundleActivityIds:
				fieldTestOptions?.bundleActivityIds.join(',') ?? stageBehaviorOptionInitialState.fieldTestOptions?.bundleActivityIds,
			bundleMaxItems: fieldTestOptions?.bundleMaxItems.toString() ?? stageBehaviorOptionInitialState.fieldTestOptions?.bundleMaxItems,
		};
	}

	/**
	 *  these useEffects are still needed because there are two separate changehandlers for the top-level properties and the
	 *  lowerBoundary table. This should probably be cleaned up at some point, if possible.
	 */
	useEffect(
		() => {
			const multiplier = parseFloat(scaledScoreLowerBoundaries.values.ThetaMultiplier);
			const verticalShift = parseFloat(scaledScoreLowerBoundaries.values.ThetaVerticalShift);
			const validParams = !Number.isNaN(multiplier) && !Number.isNaN(verticalShift);
			scaledScoreLowerBoundaries.setFieldValue(
				'lowerBoundaries',
				scaledScoreLowerBoundaries.values.lowerBoundaries.map((entry) => {
					const numericTheta = parseFloat(entry.Theta);
					return validParams && !Number.isNaN(numericTheta)
						? {
								...entry,
								ScaledScore: calculateScaledScore(numericTheta, multiplier, verticalShift).toString(),
						  }
						: { ...entry, ScaledScore: '0' };
				})
			);
		},
		// pretty sure eslint is wrong here. These are the only values that should trigger this effect
		[scaledScoreLowerBoundaries.values.ThetaMultiplier, scaledScoreLowerBoundaries.values.ThetaVerticalShift] // eslint-disable-line react-hooks/exhaustive-deps
	);

	// update min and max when vertical scale limit, theta multiplier, or theta vertical shift change
	useEffect(
		() => {
			const vertScaleLimit = Math.abs(parseFloat(scaledScoreLowerBoundaries.values.VerticalScaleLimit));
			const multiplier = parseFloat(scaledScoreLowerBoundaries.values.ThetaMultiplier);
			const verticalShift = parseFloat(scaledScoreLowerBoundaries.values.ThetaVerticalShift);
			const validParams = !Number.isNaN(vertScaleLimit) && !Number.isNaN(multiplier) && !Number.isNaN(verticalShift);
			const max = validParams ? calculateScaledScore(vertScaleLimit, multiplier, verticalShift) : 0;
			const min = validParams ? calculateScaledScore(-1 * vertScaleLimit, multiplier, verticalShift) : 0;

			scaledScoreLowerBoundaries.setFieldValue('Min', min.toString());
			scaledScoreLowerBoundaries.setFieldValue('Max', max.toString());
		},
		// pretty sure eslint is wrong here. These are the only values that should trigger this effect
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			scaledScoreLowerBoundaries.values.ThetaMultiplier,
			scaledScoreLowerBoundaries.values.ThetaVerticalShift,
			scaledScoreLowerBoundaries.values.VerticalScaleLimit,
		]
	);

	function handleDownload(event: React.MouseEvent) {
		const { lowerBoundaries, VerticalScaleLimit, ...scaledScoreParameters } = scaledScoreLowerBoundaries.values;
		const commonFields = {
			statsConfig: { ...statsConfig, VerticalScaleLimit: VerticalScaleLimit },
			stageBehaviors: stageBehaviors.values,
		};
		const monteCarloFields = { simulationSettings: simulationSettings.values };
		const productionFields = {
			basicSettings: basicSettings,
			gradeLevelDomains: gradeLevelDomains,
			thetaAndScaledScoreTable: lowerBoundaries,
			scaledScoreConfig: scaledScoreParameters,
		};
		let config;
		switch (formFormat) {
			case formFormats.MonteCarlo: {
				config = castFormToMonteCarlo(commonFields, monteCarloFields);
				break;
			}
			case formFormats.Production: {
				config = castFormToProduction(commonFields, productionFields);
				break;
			}
			default:
				config = {};
		}

		let btnElement = event.target as HTMLAnchorElement;
		btnElement.href = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(config, null, 2))}`;
	}

	function handleBasicSettingsChange(event: React.ChangeEvent) {
		const { name, value } = event.target as HTMLInputElement;
		setBasicSettings((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}

	function handleGradeLevelDomainChange(event: React.ChangeEvent) {
		const { name, value } = event.target as HTMLInputElement;
		setGradeLevelDomains((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}

	function handleStatsConfigChange(event: React.ChangeEvent) {
		const { name, value } = event.target as HTMLInputElement;
		setStatsConfig((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}

	function handleScaledScoreConfigChange(event: React.ChangeEvent) {
		const { name, value } = event.target as HTMLInputElement;
		scaledScoreLowerBoundaries.setFieldValue(name, value, true);
	}

	function handleThetaAndScaledScoreTableChange(event: React.ChangeEvent, key: string) {
		// component is set up so that only the theta can be changed, thus accessing the element name
		// is unnecessary because it will always be 'Theta'.
		const { value } = event.target as HTMLInputElement;

		let validParams =
			!Number.isNaN(parseFloat(value)) &&
			!Number.isNaN(parseFloat(scaledScoreLowerBoundaries.values.ThetaMultiplier)) &&
			!Number.isNaN(parseFloat(scaledScoreLowerBoundaries.values.ThetaVerticalShift));
		const scaledScore = validParams
			? calculateScaledScore(
					parseFloat(value),
					parseFloat(scaledScoreLowerBoundaries.values.ThetaMultiplier),
					parseFloat(scaledScoreLowerBoundaries.values.ThetaVerticalShift)
			  ).toString()
			: '0';

		scaledScoreLowerBoundaries.setFieldValue(
			'lowerBoundaries',
			scaledScoreLowerBoundaries.values.lowerBoundaries.map((entry) => {
				return entry.Grade === key
					? {
							Grade: entry.Grade,
							Theta: value,
							ScaledScore: scaledScore,
					  }
					: entry;
			}),
			true
		);
	}

	function handleStageBehaviorsChange(event: React.ChangeEvent, key: number) {
		const { name, value } = event.target as HTMLInputElement;
		stageBehaviors.setValues((prevState) =>
			prevState.map((entry) => {
				if (key !== entry.key) {
					return entry;
				}
				if (['bundleActivityIds', 'bundleMaxItems'].includes(name)) {
					return { ...entry, fieldTestOptions: { ...entry.fieldTestOptions!, [name]: value } };
				}
				return { ...entry, [name]: value };
			})
		);
	}

	function addStageBehaviorEntry() {
		// add index of added item as a salt to keep keys unique when multiple empty entries are added.
		stageBehaviors.setValues((prevState) => [
			...prevState,
			{
				...stageBehaviorOptionInitialState,
				key: stageBehaviorKeyCounter,
			},
		]);
		setStageBehaviorKeyCounter();
	}

	function deleteStageBehaviorEntry(itemKey: number) {
		stageBehaviors.setValues((prevState) => prevState.filter((entry) => itemKey !== entry.key));
	}

	function handleFormFormatChange(event: React.MouseEvent<HTMLInputElement>) {
		const { value } = event.target as HTMLInputElement;
		setFormFormat(() => value as formFormats);
	}

	return (
		<Container>
			<Card>
				<Card.Header>
					<h1>Adaptive Engine Configuration Tool</h1>
				</Card.Header>
			</Card>
			<UploadFileForm handleSubmit={handleUploadFile} />
			<Card>
				<Card.Header>Select File Format:</Card.Header>
				<Card.Body>
					<div className="row">
						<div className="col">
							<ButtonGroup toggle className="mb-2">
								<ToggleButton
									type="radio"
									name="montecarlo"
									variant="primary"
									value={formFormats.MonteCarlo}
									checked={formFormat === formFormats.MonteCarlo}
									onClick={handleFormFormatChange}>
									Monte Carlo
								</ToggleButton>
								<ToggleButton
									type="radio"
									name="production"
									variant="primary"
									value={formFormats.Production}
									checked={formFormat === formFormats.Production}
									onClick={handleFormFormatChange}>
									Production
								</ToggleButton>
							</ButtonGroup>
						</div>
					</div>
				</Card.Body>
			</Card>
			<Card>
				<Card.Header>Edit Configuration</Card.Header>
				<Card.Body>
					<ConfigForm
						handleBasicSettingsChange={handleBasicSettingsChange}
						basicSettings={basicSettings}
						handleSimulationSettingsChange={simulationSettings.handleChange}
						simulationSettings={simulationSettings.values}
						simulationSettingsErrors={simulationSettings.errors as unknown as SimulationSettingsErrors}
						handleGradeLevelDomainChange={handleGradeLevelDomainChange}
						gradeLevelDomains={gradeLevelDomains}
						handleStatsConfigChange={handleStatsConfigChange}
						statsConfig={{ ...statsConfig, VerticalScaleLimit: scaledScoreLowerBoundaries.values.VerticalScaleLimit }}
						handleScaledScoreConfigChange={handleScaledScoreConfigChange}
						scaledScoreConfig={scaledScoreLowerBoundaries.values}
						scaledScoreConfigErrors={scaledScoreLowerBoundaries.errors as unknown as ErrorReport}
						handleThetaAndScaledScoreTableChange={handleThetaAndScaledScoreTableChange}
						handleStageBehaviorsChange={handleStageBehaviorsChange}
						stageBehaviors={stageBehaviors.values}
						stageBehaviorErrors={stageBehaviors.errors as unknown as ErrorReport[]}
						addStageBehaviorEntry={addStageBehaviorEntry}
						deleteStageBehaviorEntry={deleteStageBehaviorEntry}
						formFormat={formFormat}
					/>
				</Card.Body>
			</Card>
			<Card>
				<Card.Header>Export File</Card.Header>
				<Card.Body>
					<DownloadButton
						href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify({}))}`}
						filename="catConfig.json"
						handleClick={handleDownload}
						disabled={formStatus}
					/>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default CatConfigContainer;

export function parseGroupFromLevelAttributes(chronologicalGradeLevels: undefined | String[], estimatedGradesToSkip: undefined | String[]) {
	if (chronologicalGradeLevels == null && estimatedGradesToSkip == null) {
		return '2+ Normal';
	} else if (arraysAreEqual(chronologicalGradeLevels, ['Kindergarten', 'First']) && estimatedGradesToSkip == null) {
		return 'K-1 Normal';
	} else if (
		chronologicalGradeLevels == null &&
		(arraysAreEqual(estimatedGradesToSkip, ['Kindergarten']) || arraysAreEqual(estimatedGradesToSkip, ['First']))
	) {
		return '2+ Scoring as K-1';
	} else if (chronologicalGradeLevels == null && arraysAreEqual(estimatedGradesToSkip, ['Kindergarten', 'First'])) {
		return '2+ Normal';
	} else if (arraysAreEqual(chronologicalGradeLevels, ['Kindergarten']) && estimatedGradesToSkip == null) {
		return 'K Field Testing';
	} else if (arraysAreEqual(chronologicalGradeLevels, ['First']) && estimatedGradesToSkip == null) {
		return '1 Field Testing';
	} else {
		return 'Unknown';
	}
}

export function arraysAreEqual(a: any[] | undefined, b: any[] | undefined) {
	return a?.length === b?.length && a?.every((val, index) => val === b?.[index]);
}

function calculateScaledScore(gradeTheta: number, multiplier: number, verticalShift: number): number {
	return gradeTheta * multiplier + verticalShift;
}
