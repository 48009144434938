import React from 'react';

interface NumberInputProps {
	name: string;
	step: number;
	label: string;
	onChange: (event: React.ChangeEvent) => void;
	placeholder: string;
	value: string;
	error: string;
	readonly?: boolean;
}

const NumberInput = ({ name, step, label, onChange, placeholder, value, error, readonly }: NumberInputProps) => {
	let wrapperClass = 'form-group';
	if (error && error.length > 0) {
		wrapperClass += ' has-error';
	}

	return (
		<div className={wrapperClass}>
			<label htmlFor={name}>{label}</label>
			<div className="field">
				<input
					type="number"
					step={step}
					name={name}
					className="form-control"
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					readOnly={readonly ? true : false}
				/>
				{error && <div className="alert alert-danger">{error}</div>}
			</div>
		</div>
	);
};

export default NumberInput;
