import React from 'react';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { Route, Switch } from 'react-router-dom';
import ItemContainer from './containers/item/ItemContainer';
import ItemListContainer from './containers/ItemListContainer/ItemListContainer';
import ItemsSearchContainer from './containers/ItemsSearchContainer/ItemsSearchContainer';
import NewItemContainer from './containers/NewItemContainer/NewItemContainer';

import HomeContainer from './containers/home/HomeContainer';
import LoginErrorContainer from './containers/LoginError/LoginErrorContainer';
import TemplateContainer from './containers/template/TemplateContainer';
import ActivitiesContainer from './containers/activities/ActivitiesContainer';
import PassageContainer from './containers/passage/PassageContainer';
import CatConfigContainer from './containers/catConfig/CatConfigContainer';
import AppBar from './components/AppBar/AppBar';
import getEnv from './envs';

import './App.scss';

function App() {
	const env = getEnv();
	return (
		<>
			<Security
				issuer={env.issuer}
				clientId={env.clientId}
				redirectUri={env.redirectUri}
				pkce={true}
				scopes={env.scopes}
				disableHttpsCheck={env.disableHttpsCheck}>
				<AppBar logoutUri={env.logoutUri} />
				<Switch>
					<SecureRoute path="/" exact component={HomeContainer} />
					<SecureRoute path="/items/new/" component={NewItemContainer} />
					<SecureRoute path="/items/search/:searchQuery" component={ItemsSearchContainer} />
					<SecureRoute path="/items/:itemReference" component={ItemContainer} />
					<SecureRoute path="/items" component={ItemListContainer} />
					<SecureRoute path="/activities/:activityReference" component={ActivitiesContainer} />
					<SecureRoute path="/activities" component={ActivitiesContainer} />
					<SecureRoute path="/passages" component={PassageContainer} />
					<SecureRoute path="/templates/:templateGroupName" component={TemplateContainer} />
					<SecureRoute path="/templates" component={TemplateContainer} />
					<SecureRoute path="/catconfig" component={CatConfigContainer} />
					<Route path="/implicit/callback" render={() => <LoginCallback errorComponent={LoginErrorContainer} />} />
				</Switch>
			</Security>
		</>
	);
}

export default App;
