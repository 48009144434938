import React from 'react';
import FormStageBehaviorOptions from '../../types/catConfig/configForm/stageBehaviorOptions';
import { Accordion, Card } from 'react-bootstrap';
import Bundle from './stageBehavior/Bundle';
import BundleFieldTest from './stageBehavior/BundleFieldTest';
import Testlet from './stageBehavior/Testlet';
import TestletFieldTest from './stageBehavior/TestletFieldTest';
import { ErrorReport } from '../../types/catConfig/errorReport';

export const GROUP_OPTIONS = ['2+ Normal', '2+ Scoring as K-1', 'K-1 Normal', 'K Field Testing', '1 Field Testing', 'Unknown'];
export const TESTTYPE_OPTIONS = ['bundle', 'bundleFieldTest', 'testlet', 'testletFieldTest'];

interface StageBehaviorProps {
	handleChange: (event: React.ChangeEvent, key: number) => void;
	stageBehaviors: FormStageBehaviorOptions[];
	stageBehaviorErrors: ErrorReport[];
	addEntry: () => void;
	deleteEntry: (entryKey: number) => void;
}

const StageBehaviors = ({ handleChange, stageBehaviors, stageBehaviorErrors, addEntry, deleteEntry }: StageBehaviorProps) => {
	return (
		<Accordion>
			<Card>
				<Accordion.Toggle as={Card.Header} eventKey="0">
					Stage Behaviors
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						{
							// potentially using an unstable key here!
						}
						<div className="container-fluid">
							{stageBehaviors.map((entry, index) => {
								const entryError = stageBehaviorErrors[index] ?? { test: {} };
								return renderEntry(entry, entryError as { [key: string]: ErrorReport }, handleChange, deleteEntry);
							})}
							<div className="row">
								<div className="col">
									<div className="btn btn-block btn-outline-primary" onClick={addEntry}>
										Add Stage Behavior
									</div>
								</div>
							</div>
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export function renderEntry(
	entry: FormStageBehaviorOptions,
	entryError: { [key: string]: ErrorReport },
	handleChange: (event: React.ChangeEvent, key: number) => void,
	deleteEntry: (key: number) => void
) {
	switch (entry.testType) {
		case 'bundle':
			return (
				<Bundle
					key={entry.key}
					stageBehavior={entry}
					entryError={entryError}
					handleChange={handleChange}
					deleteEntry={deleteEntry}
				/>
			);
		case 'bundleFieldTest':
			return (
				<BundleFieldTest
					key={entry.key}
					stageBehavior={entry}
					entryError={entryError}
					handleChange={handleChange}
					deleteEntry={deleteEntry}
				/>
			);
		case 'testlet':
			return (
				<Testlet
					key={entry.key}
					stageBehavior={entry}
					entryError={entryError}
					handleChange={handleChange}
					deleteEntry={deleteEntry}
				/>
			);
		case 'testletFieldTest':
			return (
				<TestletFieldTest
					key={entry.key}
					stageBehavior={entry}
					entryError={entryError}
					handleChange={handleChange}
					deleteEntry={deleteEntry}
				/>
			);
		default:
			return <div>Error Parsing Test Type</div>;
	}
}

export default StageBehaviors;
