import React from 'react';
import { textInputFactory } from './common/TextInput';
import SelectInput from './common/SelectInput';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import SimulationOptions, { SimulationSettingsErrors } from '../../types/catConfig/configForm/simulationSettings';

const CSV_FIELD_LABEL_SUFFIX = ' (Comma Separated Values)';
const BOOLEAN_SELECT_OPTIONS = ['True', 'False'];
const SUBJECT_SELECT_OPTIONS = ['ELA', 'Math'];

interface SimulationSettingsProps {
	handleChange: (event: React.ChangeEvent) => void;
	simulationSettings: SimulationOptions;
	errors: SimulationSettingsErrors;
}

const SimulationSettings = ({ handleChange, simulationSettings, errors }: SimulationSettingsProps) => {
	function simulationSettingsTextInputFactory(name: string, label: string) {
		return textInputFactory(name, label, handleChange, simulationSettings, errors);
	}
	return (
		<Accordion>
			<Card>
				<Accordion.Toggle as={Card.Header} eventKey="0">
					Simulation Settings
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<div className="container-fluid">
							<div className="row">
								<div className="col">
									<h4>Files</h4>
								</div>
							</div>
							<div className="row">
								<div className="col">{simulationSettingsTextInputFactory('SimulationTitle', 'Simulation Title')}</div>
								<div className="col">{simulationSettingsTextInputFactory('OutputPath', 'Output Path')}</div>
							</div>
							<div className="row">
								<div className="col">{simulationSettingsTextInputFactory('StudentFilePath', 'Student File Path')}</div>
								<div className="col">{simulationSettingsTextInputFactory('ItemsFilePath', 'Items File Path')}</div>
							</div>
							<div className="row">
								<div className="col-6">{simulationSettingsTextInputFactory('TestletsFilePath', 'Testlets File Path')}</div>
							</div>
							<div className="row">
								<div className="col">
									<h4>Program Options</h4>
								</div>
							</div>
							<div className="row">
								<div className="col">{simulationSettingsTextInputFactory('RandomSeed', 'Random Seed')}</div>
								<div className="col">
									{simulationSettingsTextInputFactory(
										'SimulateForGrades',
										`Simulate For Grades ${CSV_FIELD_LABEL_SUFFIX}`
									)}
								</div>
							</div>
							<div className="row">
								<div className="col">
									{simulationSettingsTextInputFactory('SimulateFirstNStudents', 'Simulate First N Students')}
								</div>
								<div className="col">{simulationSettingsTextInputFactory('NumberThreads', 'Number of Threads')}</div>
								<div className="col">{simulationSettingsTextInputFactory('Precision', 'Precision')}</div>
							</div>
							<div className="row">
								<div className="col">
									<h4>Content Options</h4>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<SelectInput
										name="AssessmentSubject"
										options={SUBJECT_SELECT_OPTIONS}
										label="Assessment Subject"
										onChange={handleChange}
										placeholder="AssessmentSubject"
										value={simulationSettings.AssessmentSubject}
										error=""
									/>
								</div>
								<div className="col">
									<SelectInput
										name="IncludeOnlyLearnosityItems"
										options={BOOLEAN_SELECT_OPTIONS}
										label="Include Only Learnosity Items"
										onChange={handleChange}
										placeholder="Include Only Learnosity Items"
										value={simulationSettings.IncludeOnlyLearnosityItems}
										error=""
									/>
								</div>
								<div className="col">
									{simulationSettingsTextInputFactory('QuestionTypeFilter', 'Question Type Filter')}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<h4>Reporting Options</h4>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<SelectInput
										name="GenerateStudentReports"
										options={BOOLEAN_SELECT_OPTIONS}
										label="Generate Student Reports"
										onChange={handleChange}
										placeholder="Generate Student Reports"
										value={simulationSettings.GenerateStudentReports}
										error=""
									/>
								</div>
								<div className="col">
									{simulationSettingsTextInputFactory(
										'LimitGeneratedStudentReportsTo',
										'Limit Generated Student Reports To'
									)}
								</div>
							</div>
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export default SimulationSettings;
