import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import WorkflowDropdown from '../WorkflowDropdown/WorkflowDropdown';
import Learnosity from '../Learnosity/Learnosity';
import { useOktaAuth } from '@okta/okta-react';
import InfoTabContent from './InfoTabContent/InfoTabContent';
import * as initService from '../../services/InitService';
import './SideBar.scss';
import { v4 } from 'uuid';

export default function SideBar({ itemReference, saveItem, isLoading, itemData }) {
	const { authState, authService } = useOktaAuth();
	const [userInfo, setUserInfo] = useState(null);
	let [learnosityInit, setLearnosityInit] = useState(null);
	let [authorApp, setAuthorApp] = useState(undefined);
	let [displayingSettings, setDisplayingSettings] = useState(false);
	let [workflowValue, setWorkflowValue] = useState('');

	const onValueChanged = useCallback(
		(value) => {
			const button = document.querySelector('.lrn-btn.lrn-author-btn.lrn-btn-lg.lrn-btn-primary');
			button.disabled = false;
			button.onclick = () => {
				setWorkflowValue(value);
				button.disabled = true;
				// We need some way to what happens when someone 'clicks' the save event.
				// Setting anything in the author app does it buuut if we set something that a user
				// is able to set in catnip there is the potential to overwrite things.
				// The item source is not used (in catnip or author.learnosity.com) so this triggers a save.
				// Setting it to a GUID to make sure that its seen as a new update every time a change happens.
				authorApp.setItemSource(`CATnip-${v4()}`);
			};
		},
		[authorApp]
	);

	useEffect(() => {
		if (!authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setUserInfo(null);
		} else {
			authService.getUser().then((info) => {
				setUserInfo(info);
			});
		}
	}, [authState, authService]); // Update if authState changes

	useEffect(() => {
		if (userInfo && itemReference) {
			initService.getSettingsInit(itemReference, window.location.hostname, userInfo, authState.accessToken).then((init) => {
				setLearnosityInit(init);
			});
		}
	}, [authState.accessToken, itemReference, userInfo]); // Update if authState changes

	useEffect(()=>{
		const containerTab = document.querySelector('.lrn-author-item-settings-container .tab-content');
		if(containerTab) {
			updateInfoTab();
		}
	},[itemData])

	useEffect(() => {
		if (displayingSettings) {
			const applyButton = document.querySelector(
				'div.lrn-author-item-settings-footer > div > button.lrn-btn.lrn-author-btn.lrn-btn-lg.lrn-btn-primary'
			);

            if (!learnosityInit.config.item_edit.item.save) {
                applyButton.style.display = "none";
            }

			applyButton.textContent = 'SAVE';

			const details = document.querySelector('.lrn-author-settings-details');
			const reactSubComponent = document.createElement('div');
			details.prepend(reactSubComponent);
			let itemTags = authorApp.getItemTags();
			let status = itemTags.find((tag) => tag.type === 'Status');
			setWorkflowValue(status?.name || 'In Authoring');
			ReactDOM.render(
				<React.StrictMode>
					<WorkflowDropdown
						valueChanged={onValueChanged}
						workflowStatus={status?.name}
					/>
				</React.StrictMode>,
				reactSubComponent
			);
		}
	}, [authorApp, displayingSettings, learnosityInit, onValueChanged]);

	function getAuthorApp(authorApp) {
		setAuthorApp(authorApp);
	}

	function onNavigate(event) {
		if (event.data.route === 'items/:reference') {
			event.preventDefault();

			let itemTags = authorApp.getItemTags();
			itemTags = itemTags.filter((tag) => tag.type !== 'Status');
			itemTags.push({ type: 'Status', name: workflowValue });
			let status = authorApp.getStatus();
			let description = authorApp.getItemDescription();
			let notes = authorApp.getItemNotes();
			saveItem(itemTags, status, description, notes);
		}
		if (event.data.route === 'items/:reference/settings') {
			setDisplayingSettings(true);
		}
	}

	function showingInfoTab() {
		const tabButtons = document.querySelectorAll('.lrn-author-tab');
		tabButtons.forEach((button, index) => {
			if( index === 2 ) {
				button.ariaSelected = "true";
				button.classList.add("active");
			} else {
				button.ariaSelected = "false";
				button.classList.remove("active");
			}
		});
		showContent("info");
	}

	function showContent(tab) {
		const tabContainers = document.querySelectorAll('.tab-pane');
		tabContainers.forEach((element)=>{
			let attributeValue = element.getAttribute("data-tab-content");
			if(attributeValue === tab) {
				element.style.display = "block";
			} else {
				element.style.display = "none";
			}
		});
	}

	function onItemRendered() {
		authorApp.navigate(`items/${itemReference}/settings`);
		createInfoTab();		
	}

	function updateInfoTab() {
		const containerTabInfo = document.querySelector('[data-tab-content="info"]');
		if(containerTabInfo) {
			renderInfoTab(containerTabInfo);
		}
	}

	function createInfoTab() {
		setTimeout(()=>{
			const containerTabs = document.querySelector('.lrn-author-nav');
			const containerTab = document.querySelector('.lrn-author-item-settings-container .tab-content');
			const reactSubComponentContainerTab = document.createElement('div');
			reactSubComponentContainerTab.setAttribute("data-tab-content", "info");
			reactSubComponentContainerTab.setAttribute("class", "tab-pane");
			reactSubComponentContainerTab.setAttribute("style", "display: none");
			containerTab.append(reactSubComponentContainerTab);
			const reactSubComponent = document.createElement('li');
			reactSubComponent.setAttribute("role", "tab");
			reactSubComponent.setAttribute("class", "lrn-author-tab");
			reactSubComponent.setAttribute("aria-selected", "false");
			const tabTagsFirst = containerTabs.querySelectorAll('li')[0];
			const tabTagsSecond = containerTabs.querySelectorAll('li')[1];
			tabTagsFirst.onclick = () => { showContent("details") };
			tabTagsSecond.onclick = () => { showContent("tags") };
			containerTabs.append(reactSubComponent);
			ReactDOM.render(
				<React.StrictMode>
					<a className="lrn-author-tab-link" data-tab-id="info" data-toggle="info" aria-label="Info" onClick={()=>{showingInfoTab()}}>
						<div className="lrn-search-small" data-authorapi-tooltip="Information">
							<span className="k-icon k-i-info k-icon-style"></span>
						</div>
						<div className="lrn-author-tab-header">
							<span className="lrn-author-button-text">Information</span>
						</div>
					</a>
					<span className="lrn-active-tab-marker"></span>
				</React.StrictMode>,
				reactSubComponent
			);
			renderInfoTab(reactSubComponentContainerTab);
		},500);
	}

	function renderInfoTab(container) {
		ReactDOM.render(
			<React.StrictMode>
				<div>
					<InfoTabContent itemData={itemData}/>
				</div>
			</React.StrictMode>,
			container
		);
	}

	if (isLoading) {
		return null;
	}

	return (
		<div id="sideBar" className="">
			<Learnosity init={learnosityInit} navigate={onNavigate} getAuthorApp={getAuthorApp} itemRendered={onItemRendered} />
		</div>
	);
}
