import React from 'react';
import { ErrorReport } from '../../../types/catConfig/errorReport';
import { textInputFactory } from '../common/TextInput';
import SelectInput from '../common/SelectInput';
import StageBehaviorEntryProps from '../../../types/catConfig/configForm/stageBehaviorProps';
import { Card } from 'react-bootstrap';
import { TESTTYPE_OPTIONS } from '../StageBehaviors';
import Header from './Header';

const Bundle = ({ handleChange, stageBehavior, entryError, deleteEntry }: StageBehaviorEntryProps) => {
	const createBundleTextInput = (name: string, label: string) =>
		textInputFactory(name, label, (event) => handleChange(event, stageBehavior.key), stageBehavior, entryError);
	const emptyfieldTestOptionsError = {
		bundleActivityIds: { test: false },
		bundleMaxItems: { test: false },
	};
	return (
		<div className="row">
			<div className="col">
				<Card>
					<Card.Header>
						<Header
							stageBehavior={stageBehavior}
							entryError={entryError}
							handleChange={handleChange}
							deleteEntry={deleteEntry}
						/>
					</Card.Header>
					<Card.Body>
						<div className="row">
							<div className="col">
								<SelectInput
									name="testType"
									label="testType"
									onChange={(event) => handleChange(event, stageBehavior.key)}
									placeholder="Test Type"
									value={stageBehavior.testType}
									options={TESTTYPE_OPTIONS}
									error=""
								/>
							</div>
							<div className="col">{createBundleTextInput('upperBoundThetaGrade', 'Upper Bound Theta Grade')}</div>
							<div className="col">
								{createBundleTextInput('useDomainsEstimatedFromStage', 'Use Domains Estimated From Stage')}
							</div>
						</div>
						<div className="row">
							<div className="col">{createBundleTextInput('overallStdErrTolerance', 'Overall Standard Error Tolerance')}</div>
							<div className="col">{createBundleTextInput('domainStdErrTolerance', 'Domain Standard Error Tolerance')}</div>
						</div>
						<div className="row">
							<div className="col">{createBundleTextInput('maxPercentPolyItems', 'Max Percent Poly Items')}</div>
							<div className="col">{createBundleTextInput('itemMin', 'Item Min')}</div>
							<div className="col">{createBundleTextInput('itemMax', 'Item Max')}</div>
						</div>
						<div className="row">
							<div className="col">
								<h4>Field Test Options</h4>
							</div>
						</div>
						<div className="row">
							<div className="col">
								{textInputFactory(
									'bundleActivityIds',
									'Bundle Activity Ids (CSV)',
									(event) => handleChange(event, stageBehavior.key),
									stageBehavior.fieldTestOptions!,
									(entryError.fieldTestOptions ?? emptyfieldTestOptionsError) as { [key: string]: ErrorReport }
								)}
							</div>
							<div className="col">
								{textInputFactory(
									'bundleMaxItems',
									'Bundle Max Items',
									(event) => handleChange(event, stageBehavior.key),
									stageBehavior.fieldTestOptions!,
									(entryError.fieldTestOptions ?? emptyfieldTestOptionsError) as { [key: string]: ErrorReport }
								)}
							</div>
						</div>
					</Card.Body>
				</Card>
			</div>
		</div>
	);
};

export default Bundle;
