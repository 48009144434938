import { ConfigOptions } from '../../types/catConfig/config';
import BasicSettings from '../../types/catConfig/configForm/basicSettings';
import SimulationOptions from '../../types/catConfig/configForm/simulationSettings';
import StatisticalParameters from '../../types/catConfig/configForm/statisticalParameters';
import ScaledScoreParameters from '../../types/catConfig/configForm/scaledScoreLowerBoundaries';
import GradeLevelBoundaryEntry from '../../types/catConfig/configForm/gradeLevelBoundaries';
import FormStageBehaviorOptions from '../../types/catConfig/configForm/stageBehaviorOptions';
import GradeLevelDomains from '../../types/catConfig/configForm/gradeLevelDomains';

export const configInitialState: ConfigOptions = {
	// prod-specific attributes
	Subject: '',
	ItemPoolId: '',
	SchoolYearStartMonth: 7,
	SchoolYearStartDay: 1,
	// monteCarlo-specific attributes
	SimulationTitle: '',
	OutputPath: '',
	StudentFilePath: '',
	ItemsFilePath: '',
	TestletsFilePath: '',
	RandomSeed: '',
	GenerateStudentReports: true,
	LimitGeneratedStudentReportsTo: 0,
	SimulateFirstNStudents: 0,
	IncludeOnlyLearnosityItems: false,
	QuestionTypeFilter: [],
	AssessmentSubject: 'math',
	SimulateForGrades: [],
	NumberThreads: 1,
	Precision: 1,
	GradeLevelDomains: {},
	LowerThetaBoundaries: {
		Kindergarten: 0,
		First: 0,
		Second: 0,
		Third: 0,
		Fourth: 0,
		Fifth: 0,
		Sixth: 0,
		Seventh: 0,
		Eighth: 0,
		Ninth: 0,
		Tenth: 0,
		Eleventh: 0,
	},
	ScaledScoreConfig: {
		ThetaMultiplier: 0,
		ThetaVerticalShift: 0,
		Min: 0,
		Max: 0,
		lowerBoundaries: {
			Kindergarten: 0,
			First: 0,
			Second: 0,
			Third: 0,
			Fourth: 0,
			Fifth: 0,
			Sixth: 0,
			Seventh: 0,
			Eighth: 0,
			Ninth: 0,
			Tenth: 0,
			Eleventh: 0,
		},
	},
	StatsConfig: {
		D: 0,
		VerticalScaleLimit: 0,
		NewtonRaphsonAdjustmentLimit: 0,
		PopulationMean: 0,
		BayesModalPopVar: 0,
		MaxLikelihoodPopVar: 0,
	},
	stageBehaviorOptions: [
		{
			testType: 'bundle',
			stage: 1,
			interstitialItemId: 'Interstitial_Stage_1',
			estimatedGradesToSkip: ['Kindergarten', 'First'],
			maxTestlets: 2,
		},
	],
};

export const basicSettingsInitialState: BasicSettings = {
	Subject: '',
	ItemPoolId: '',
	SchoolYearStartMonth: '7',
	SchoolYearStartDay: '1',
};

export const simulationSettingsInitialState: SimulationOptions = {
	SimulationTitle: '',
	OutputPath: '',
	StudentFilePath: '',
	ItemsFilePath: '',
	TestletsFilePath: '',
	RandomSeed: '',
	GenerateStudentReports: 'true',
	LimitGeneratedStudentReportsTo: '',
	SimulateFirstNStudents: '',
	IncludeOnlyLearnosityItems: 'false',
	QuestionTypeFilter: '',
	AssessmentSubject: '',
	SimulateForGrades: '',
	NumberThreads: '',
	Precision: '',
};

export const gradeLevelDomainsInitialState: GradeLevelDomains = {
	PreK: '',
	Kindergarten: '',
	First: '',
	Second: '',
	Third: '',
	Fourth: '',
	Fifth: '',
	Sixth: '',
	Seventh: '',
	Eighth: '',
	Ninth: '',
	Tenth: '',
	Eleventh: '',
	Twelfth: '',
};

export const statsConfigFormInitialState: StatisticalParameters = {
	D: '1.0',
	VerticalScaleLimit: '0.0',
	NewtonRaphsonAdjustmentLimit: '0.0',
	PopulationMean: '0.0',
	BayesModalPopVar: '0.0',
	MaxLikelihoodPopVar: '0.0',
};

export const thetaAndScaledScoreTableInitialState: GradeLevelBoundaryEntry[] = [
	{ Grade: 'PreK', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Kindergarten', Theta: '0', ScaledScore: '0' },
	{ Grade: 'First', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Second', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Third', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Fourth', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Fifth', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Sixth', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Seventh', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Eighth', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Ninth', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Tenth', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Eleventh', Theta: '0', ScaledScore: '0' },
	{ Grade: 'Twelfth', Theta: '0', ScaledScore: '0' },
];

export const scaledScoreLowerBoundariesInitialState: ScaledScoreParameters = {
	ThetaMultiplier: '0.0',
	ThetaVerticalShift: '0.0',
	VerticalScaleLimit: '0.0',
	Min: '0.0',
	Max: '0.0',
	lowerBoundaries: thetaAndScaledScoreTableInitialState,
};

export const stageBehaviorInitialState: FormStageBehaviorOptions[] = [];

export const stageBehaviorOptionInitialState: FormStageBehaviorOptions = {
	key: 0,
	testType: 'bundle',
	stage: '1',
	chronologicalGradeLevels: '',
	estimatedGradesToSkip: '',
	interstitialItemId: 'Interstitial_Stage_1',
	upperBoundThetaGrade: '',
	itemMin: '',
	itemMax: '',
	maxPercentPolyItems: '',
	overallStdErrTolerance: '',
	domainStdErrTolerance: '',
	useDomainsEstimatedFromStage: '',
	itemLimit: '',
	bundleActivityId: '',
	maxTestlets: '',
	testletActivityIds: '',
	fieldTestOptions: {
		bundleActivityIds: '',
		bundleMaxItems: '',
	},
};
