import * as Yup from 'yup';
import { requiredTest, numericTest, numericMinTest, numericMaxTest } from './utils/customValidationTests';
import { parseErrorReportFromSchemaDescription } from './utils/schemaValidator';
import locale from './locale';

export const VERTICAL_SCALE_LIMIT_MAX = 12;
export const VERTICAL_SCALE_LIMIT_MIN = -VERTICAL_SCALE_LIMIT_MAX;

export const thetaTableStaticSchema = Yup.object().shape({
	// the validation rules will be dynamically generated in the validator function, so these are arbitrary.
	lowerBoundaries: Yup.array().of(
		Yup.object().shape({
			Grade: Yup.string(),
			Theta: Yup.string(),
			ScaledScore: Yup.string(),
		})
	),
});

export const scaledScoreParametersStaticSchema = Yup.object().shape({
	ThetaMultiplier: Yup.string().test(requiredTest).test(numericTest),
	ThetaVerticalShift: Yup.string().test(requiredTest).test(numericTest),
	VerticalScaleLimit: Yup.string()
		.test(requiredTest)
		.test(numericTest)
		.test(numericMinTest(VERTICAL_SCALE_LIMIT_MIN))
		.test(numericMaxTest(VERTICAL_SCALE_LIMIT_MAX)),
	// automatically generated so no validation needed.
	Min: Yup.string(),
	Max: Yup.string(),
});

export const scaledScoreLowerBoundariesStaticSchema = scaledScoreParametersStaticSchema.concat(thetaTableStaticSchema);

export const scaledScoreLowerBoundariesErrorReport = parseErrorReportFromSchemaDescription(
	scaledScoreLowerBoundariesStaticSchema.describe(),
	locale
);
