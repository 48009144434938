import React, { useState, useEffect, useRef } from 'react';
import Learnosity from '../../components/Learnosity/Learnosity';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import * as initService from '../../services/InitService';
import DOMPurify from 'dompurify';

function NewItemContainer() {
	const { authState, authService } = useOktaAuth();
	const [userInfo, setUserInfo] = useState(null);
	let [learnosityInit, setLearnosityInit] = useState(null);
	let [authorApp, setAuthorApp] = useState(undefined);
	let history = useHistory();
	let location = useLocation();
	const internalRoute = useRef(false);
	let item = location?.state?.item;
	let jsonItem = location?.state?.jsonItem;

	useEffect(() => {
		if (!authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setUserInfo(null);
		} else {
			authService.getUser().then((info) => {
				setUserInfo(info);
			});
		}
	}, [authState, authService]); // Update if authState changes

	useEffect(() => {
		if (userInfo) {
			setLearnosityInit(initService.getItemsListInit(window.location.hostname, userInfo));
		}
	}, [userInfo]); // Update if authState changes

	useEffect(() => {
		if (authorApp && jsonItem) {
			console.log('navigate to: items/' + jsonItem);
			internalRoute.current = true;
			authorApp.navigate('items/' + encodeURIComponent(jsonItem));
		}
	}, [authorApp, jsonItem]);

	function onSaveSuccess(event) {
		console.log('Onsave');
		if (location.pathname === '/items/new') {
			history.push(`/items/${event.data.item.reference}`);
		}
	}

	function getAuthorApp(authorApp) {
		setAuthorApp(authorApp);
	}

	return (
		<>
			<div className="flex divide-x ">
				<div className="w-2/3" id="item">
					<Learnosity init={learnosityInit} getAuthorApp={getAuthorApp} saveSuccess={onSaveSuccess} />
				</div>
				<div className="w-1/3 p-10">
					<h4>About this template</h4>
					<p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.item.note) }}></p>
				</div>
			</div>
		</>
	);
}

export default NewItemContainer;
