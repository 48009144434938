import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { ErrorReport } from '../../types/catConfig/errorReport';
import ThetaAndScaledScoreTable from './ThetaAndScaledScoreTable';
import { textInputFactory } from './common/TextInput';
import scaledScoreLowerBoundaries from '../../types/catConfig/configForm/scaledScoreLowerBoundaries';

interface ScaledScoreConfigProps {
	handleChange: (event: React.ChangeEvent) => void;
	scaledScoreConfig: scaledScoreLowerBoundaries;
	scaledScoreConfigErrors: ErrorReport;
	handleTableChange: (event: React.ChangeEvent, key: string) => void;
}

const ScaledScoreConfig = ({ handleChange, scaledScoreConfig, scaledScoreConfigErrors, handleTableChange }: ScaledScoreConfigProps) => {
	const { lowerBoundaries: lowerBoundaryValues, ...parameterValues } = scaledScoreConfig;
	const { lowerBoundaries: lowerBoundaryErrors, ...parameterErrors } = scaledScoreConfigErrors;
	function scaledScoreParameterTextInput(name: string, label: string) {
		return textInputFactory(
			name,
			label,
			handleChange,
			parameterValues as { [key: string]: string },
			parameterErrors as { [key: string]: ErrorReport }
		);
	}
	return (
		<Accordion>
			<Card>
				<Accordion.Toggle as={Card.Header} eventKey="0">
					Scaled Score Configuration
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<div className="container-fluid">
							<div className="row">
								<div className="col">{scaledScoreParameterTextInput('ThetaMultiplier', 'Theta Multiplier')}</div>
								<div className="col">{scaledScoreParameterTextInput('ThetaVerticalShift', 'Theta Vertical Shift')}</div>
								<div className="col">
									<label htmlFor="min">Min Scaled Score</label>
									<div id="min">{parameterValues.Min}</div>
								</div>
								<div className="col">
									<label htmlFor="max">Max Scaled Score</label>
									<div id="max">{parameterValues.Max}</div>
								</div>
							</div>
							<div className="row">
								<div className="col">
									<ThetaAndScaledScoreTable
										handleTableChange={handleTableChange}
										tableValues={scaledScoreConfig.lowerBoundaries}
										tableErrors={scaledScoreConfigErrors.lowerBoundaries! as unknown as ErrorReport[]}
									/>
								</div>
							</div>
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export default ScaledScoreConfig;
