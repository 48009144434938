import React from 'react';
import './InfoTabContent.scss';

export default function InfoTabContent({itemData}) {

    if(itemData === null) {
        return (
            <div className='center'>
                <h1>Loading ...</h1>
            </div> 
        )
    }

    if(itemData && itemData.itemDatas.length === 0) {
        return (
            <div className='center'>
                <h1>No values</h1>
            </div> 
        )
    }

    const data = itemData.itemDatas[0];
    const dataInfo = data ? [
        {name: "a_value" , value: data.aValueUnscaled ?? 'No defined' },
        {name: "b_values" , value:  data.bValuesUnscaled ?? 'No defined'},
        {name: "c_value" , value:  data.cValue ?? 'No defined'},
        {name: "a_value_scaled" , value: data.aValueScaled ?? 'No defined'},
        {name: "b_values_scaled" , value: data.bValuesScaled ?? 'No defined'},
        {name: "Total Students" , value: data.studentCount ?? 'No defined'},
    ] : null;

    return (
        <div>
            {dataInfo &&
                (dataInfo).map((element) => { 
                    return (
                        <h1 key={element.name} className='info-title-element'>{element.name} : 
                            <span className='info-value-element'>
                            { " " + element.value }
                            </span>
                        </h1>
                    )
                    })
            }
        </div> 
    )
};