import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import GradeLevelDomainEntries from '../../types/catConfig/configForm/gradeLevelDomains';
import TextInput from './common/TextInput';

interface GradeLevelDomainProps {
	handleChange: (event: React.ChangeEvent) => void;
	gradeLevelDomains: GradeLevelDomainEntries;
}

const GradeLevelDomains = ({ handleChange, gradeLevelDomains }: GradeLevelDomainProps) => {
	return (
		<Accordion>
			<Card>
				<Accordion.Toggle as={Card.Header} eventKey="0">
					Grade Level Domains
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						<div className="container-fluid">
							{Object.entries(gradeLevelDomains).map(([k, v]) => {
								return (
									<div className="row" key={k}>
										<div className="col-3">
											<TextInput name={k} label={k} onChange={handleChange} placeholder={k} value={v} error="" />
										</div>
									</div>
								);
							})}
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export default GradeLevelDomains;
