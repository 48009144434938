import React from 'react';

interface SelectInputProps {
	name: string;
	label: string;
	onChange: (event: React.ChangeEvent) => void;
	placeholder: string;
	value: string;
	error: string;
	options: Array<string>;
}

const SelectInput = ({ name, label, onChange, placeholder, value, error, options }: SelectInputProps) => {
	let wrapperClass = 'form-group';
	if (error && error.length > 0) {
		wrapperClass += ' has-error';
	}

	return (
		<div className={wrapperClass}>
			<label htmlFor={name}>{label}</label>
			<div className="field">
				<select name={name} className="form-control" placeholder={placeholder} onChange={onChange} value={value}>
					{options.map((option) => {
						return (
							<option key={option} value={option}>
								{option}
							</option>
						);
					})}
				</select>
				{error && <div className="alert alert-danger">{error}</div>}
			</div>
		</div>
	);
};

export default SelectInput;
