import React from 'react';

interface DownloadButtonProps {
	readonly href: string;
	readonly filename: string;
	handleClick: (event: React.MouseEvent) => void;
	disabled: boolean;
}

function DownloadButton({ href, filename, handleClick, disabled }: DownloadButtonProps) {
	return (
		<>
			{disabled ? (
				<button className="btn btn-primary btn-disabled" disabled>
					Download {filename}
				</button>
			) : (
				<a className="btn btn-primary" onClick={handleClick} href={href} download={filename} type="button">
					Download {filename}
				</a>
			)}
		</>
	);
}

export default DownloadButton;
