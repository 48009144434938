import * as templateService from './TemplateService';
import * as learnosityApi from './../api/imagineLearnosityApi'

const templateEditors = [
    'michelle.bowen@imaginelearning.com',
    'alicia.quan@imaginelearning.com',
];

const superUsers = [
    'michelle.bowen@imaginelearning.com',
    "scott.cunningham@imaginelearning.com",
    "eleanor.gallagher@imaginelearning.com",
    "gina.gress@imaginelearning.com",
    "matt.duschinski@imaginelearning.com",
    "michelle.ross@imaginelearning.com"
];

export async function canEditItem(itemReference, user, token) {
    // You can only edit a template if you are on the template editor list
    let templatesNames = await templateService.getTemplateNames(token);
    if (templatesNames.includes(itemReference)) {
        let isTemplateEditor = templateEditors.includes(user.email)
        console.log(`This item is tagged as a template, is template editor: ${isTemplateEditor}`)
        return isTemplateEditor;
    }

    let itemInformation = await learnosityApi.getItems(token, [itemReference]);
    let itemStatus = itemInformation[0].tags.Status;
    // if an item is Operational or certified you have to be a super user to edit
    if (itemStatus !== undefined && (itemStatus[0] === 'Operational' || itemStatus[0] === 'Certified')) {
        let isSuperUser = superUsers.includes(user.email)
        console.log(`This item is tagged operational or certified, is super user: ${isSuperUser}`)
        return isSuperUser;
    }

    // If the item is not a template, or a certified or operational item anyone can edit it
    return true;
}
