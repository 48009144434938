import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';

interface UploadFileFormProps {
	handleSubmit: (event: React.FormEvent) => void;
}

function UploadFileForm({ handleSubmit }: UploadFileFormProps) {
	const [fileSelected, setFileSelected] = useState(false);
	function handleChange(): void {
		if (!fileSelected) {
			setFileSelected(true);
		}
	}

	return (
		<Card>
			<Card.Header>Import Existing Configuration</Card.Header>
			<Card.Body>
				<form onSubmit={handleSubmit}>
					<input type="file" id="file" name="file" onChange={handleChange}></input>
					<button type="submit" className="btn btn-primary" disabled={!fileSelected}>
						Upload Config File
					</button>
				</form>
			</Card.Body>
		</Card>
	);
}

export default UploadFileForm;
