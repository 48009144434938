import { handleResponse, handleError } from './apiUtils';
import getEnv from '../envs';
const baseUrl = getEnv().imagineLearnosityApiUrl;
const itemCalibrationBaseURL = getEnv().itemCalibrationBaseURL;

export function getAuthorInit(init, token) {
	return fetch(baseUrl + '/init/author?product=cat', {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
		body: JSON.stringify(init),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getTemplates(token) {
	return fetch(baseUrl + '/templates?product=cat', {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getItems(token, itemReferences) {
	return fetch(baseUrl + '/Items/Okta?product=cat', {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
        body: JSON.stringify({
            itemReferences}),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getPassageItemMaps(filter, token) {
	return fetch(baseUrl + '/Passages/ItemMap/Filter?product=cat', {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
		body: JSON.stringify(filter),
	})
		.then(handleResponse)
		.catch(handleError);
}

export function getItemParameters(itemIds, token) {
	return fetch(itemCalibrationBaseURL, {
		method: 'POST',
		headers: {
			'content-type': 'application/json',
			Authorization: 'Bearer ' + token,
		},
		body: JSON.stringify(itemIds),
	})
		.then(handleResponse)
		.catch(handleError);
}
