import * as Yup from 'yup';
import { ErrorReport } from '../../../../types/catConfig/errorReport';
import { SchemaDescription, SchemaObjectDescription, SchemaInnerTypeDescription, SchemaFieldDescription, AnySchema } from 'yup/lib/schema';
import { ExtraParams } from 'yup/lib/types';
import locale, { CustomLocale, ARRAY_ENTRY_KEY } from '../locale';
import { numericMaxTest, numericMinTest, numericTest, requiredTest } from './customValidationTests';
import scaledScoreLowerBoundaries from '../../../../types/catConfig/configForm/scaledScoreLowerBoundaries';
import { VERTICAL_SCALE_LIMIT_MAX, scaledScoreParametersStaticSchema } from '../scaledScoreLowerBoundaries';
import StageBehaviorOptions from '../../../../types/catConfig/configForm/stageBehaviorOptions';
import {
	bundleFieldTestValidationSchema,
	bundleValidationSchema,
	testletValidationSchema,
	testletFieldTestValidationSchema,
} from '../stageBehaviors';

Yup.setLocale(locale);

const YUP_OPTIONS = { strict: false, abortEarly: false };

/**
 * This function relies on a custom locale object (see Yup validation library documentation).
 * This should generate a default set of ErrorReports for each schema so that all possible errors for each field
 * will always be displayed.
 */
export function parseErrorReportFromSchemaDescription(schemaDescription: SchemaFieldDescription, locale: CustomLocale): ErrorReport {
	switch (schemaDescription.type) {
		case 'object': {
			const description = schemaDescription as SchemaObjectDescription;
			console.log(
				Object.fromEntries(
					Object.entries(description.fields).map(([k, v]) => {
						return [k, parseErrorReportFromSchemaDescription(v, locale)];
					})
				)
			);
			return Object.fromEntries(
				Object.entries(description.fields).map(([k, v]) => {
					return [k, parseErrorReportFromSchemaDescription(v, locale)];
				})
			);
		}
		case 'array': {
			const description = schemaDescription as SchemaInnerTypeDescription;
			return parseErrorReportFromSchemaDescription(description.innerType!, locale);
		}
		default: {
			const description = schemaDescription as SchemaDescription;

			return Object.fromEntries([
				...description.tests
					.filter((test: any) => {
						return !test.name!.includes(ARRAY_ENTRY_KEY);
					})
					.map((test: any) => {
						return [getErrorMessageForTestFromLocale(locale[description.type]!, test)];
					}),
				[
					'arrayEntry',
					Object.fromEntries(
						description.tests
							.filter((test: any) => test.name!.includes(ARRAY_ENTRY_KEY))
							.map((test: any) => {
								return [getErrorMessageForTestFromLocale(locale[description.type]!, test)];
							})
					),
				],
			]);
		}
	}
}

function getErrorMessageForTestFromLocale(
	localeType: { [key: string]: string | ((val: number) => string) | undefined },
	test: { name?: string | undefined; params: ExtraParams | undefined }
) {
	const localeEntry = localeType[test.name!];
	return 'function' === typeof localeEntry ? localeEntry(Object.values(test.params!)[0] as number) : localeEntry;
}

/**
 * This is a HOF that creates a custom validation function for each validation schema.
 * These should be used as the validation function with Formik.
 * array
 */
export function schemaValidator(schema: Yup.AnySchema) {
	return function (values: any): ErrorReport | ErrorReport[] {
		const errors = parseErrorReportFromSchemaDescription(schema.describe(), locale);
		if (Array.isArray(values)) {
			const arrayInnerSchema = (schema as any).innerType!;
			return values.map((entry) => validateSchema(entry, errors, arrayInnerSchema));
		} else {
			return validateSchema(values, errors, schema);
		}
	};
}

function validateSchema(values: any, errors: object, schema: AnySchema): ErrorReport {
	try {
		schema.validateSync(values, YUP_OPTIONS);
		return errors as ErrorReport;
	} catch (validationError) {
		let inner:any = [];
		if(validationError) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			inner = validationError.inner
		}
		return Object.fromEntries(
			Object.entries(errors).map(([fieldName, fieldErrorReport]) => {
				const fieldErrors = inner.filter((entry: any) => entry.path === fieldName);
				const wholeFieldErrors = fieldErrors.filter((entry: any) => !entry.params[ARRAY_ENTRY_KEY]);

				const wholeFieldErrorReport: ErrorReport = Object.fromEntries(
					Object.entries(fieldErrorReport)
						.filter(([errorMessage]) => errorMessage !== ARRAY_ENTRY_KEY)
						.map(([errorMessage]) => {
							return [errorMessage, wholeFieldErrors.some((entry: any) => entry.message === errorMessage)];
						})
				);

				const arrayErrors = fieldErrors.filter((entry: any) => entry.params[ARRAY_ENTRY_KEY]);
				if (fieldErrorReport[ARRAY_ENTRY_KEY]) {
					const arrayEntryFieldReport = Object.fromEntries(
						Object.entries(fieldErrorReport[ARRAY_ENTRY_KEY]!).map(([errorMessage]) => {
							return [errorMessage, arrayErrors.some((entry: any) => entry.message === errorMessage)];
						})
					);
					wholeFieldErrorReport[ARRAY_ENTRY_KEY] = arrayEntryFieldReport;
				}
				return [fieldName, wholeFieldErrorReport];
			})
		);
	}
}

function parseFloatWithDefault(value: string, defaultVal: number): number {
	const castValue = parseFloat(value);
	return !Number.isNaN(castValue) ? castValue : defaultVal;
}

export const scaledScoreLowerBoundariesValidator = async (values: scaledScoreLowerBoundaries): Promise<ErrorReport> => {
	const { lowerBoundaries, ...flatFields } = values;
	const castVerticalScaleLimit = parseFloatWithDefault(flatFields.VerticalScaleLimit, VERTICAL_SCALE_LIMIT_MAX);
	// there is no guarantee that vertical scale limit is positive, though it's value should be treated as an absolute value
	const positiveVerticalScaleLimit = Math.abs(castVerticalScaleLimit);
	let lastValidMin = -positiveVerticalScaleLimit;
	const tableSchema: ErrorReport[] = lowerBoundaries.map((value, index) => {
		const prevVal = index !== 0 ? parseFloat(values.lowerBoundaries[index - 1].Theta) : lastValidMin;
		lastValidMin = !Number.isNaN(prevVal) ? Math.max(prevVal, lastValidMin) : lastValidMin;
		const minVal = lastValidMin;
		const entrySchema = Yup.object().shape({
			Grade: Yup.string(),
			Theta: Yup.string()
				.test(requiredTest)
				.test(numericTest)
				.test(numericMinTest(minVal))
				.test(numericMaxTest(positiveVerticalScaleLimit)),
			ScaledScore: Yup.string(),
		});
		return validateSchema(value, parseErrorReportFromSchemaDescription(entrySchema.describe(), locale), entrySchema);
	});
	const flatFieldErrors = validateSchema(
		flatFields,
		parseErrorReportFromSchemaDescription(scaledScoreParametersStaticSchema.describe(), locale),
		scaledScoreParametersStaticSchema
	);
	return {
		...flatFieldErrors,
		lowerBoundaries: tableSchema,
	};
};

export function stageBehaviorValidator(values: StageBehaviorOptions[]): ErrorReport[] {
	return values.map((entry) => {
		let entrySchema;
		switch (entry.testType) {
			case 'bundle':
				// this is messy because validateSchema doesn't work recursively for nested objects yet.
				return {
					...validateSchema(
						entry,
						parseErrorReportFromSchemaDescription(bundleValidationSchema.describe(), locale),
						bundleValidationSchema
					),
					fieldTestOptions: validateSchema(
						entry.fieldTestOptions,
						parseErrorReportFromSchemaDescription(bundleValidationSchema.fields.fieldTestOptions.describe(), locale),
						bundleValidationSchema.fields.fieldTestOptions
					),
				};
			case 'bundleFieldTest':
				entrySchema = bundleFieldTestValidationSchema;
				break;
			case 'testlet':
				entrySchema = testletValidationSchema;
				break;
			case 'testletFieldTest':
				entrySchema = testletFieldTestValidationSchema;
				break;
			default:
				throw new Error('Stage Behavior Test Type could not be parsed');
		}
		return validateSchema(entry, parseErrorReportFromSchemaDescription(entrySchema.describe(), locale), entrySchema);
	});
}

export function detectFormikHasError(error: ErrorReport | ErrorReport[]): boolean {
	if (Array.isArray(error)) {
		return error.some((entry) => detectFormikHasError(entry));
	}
	return Object.values(error).some((entry) => {
		if (entry == null) {
			return false;
		}
		if (typeof entry === 'boolean') {
			return entry;
		} else {
			return detectFormikHasError(entry);
		}
	});
}
