import React, { useEffect, useState } from 'react';
import * as learnosityApi from '../../api/imagineLearnosityApi';
import { useOktaAuth } from '@okta/okta-react';

export default function Learnosity(props) {
	let [authorApp, setAuthorApp] = useState(undefined);
	let [learnosityReady, setLearnosityReady] = useState(false);
	const { authState } = useOktaAuth();

	useEffect(() => {
		if (props.init) {
			learnosityApi.getAuthorInit(props.init, authState.accessToken).then((signedInit) => {
				let learnosityAuthor = window.LearnosityAuthor;
				setAuthorApp(
					learnosityAuthor.init(signedInit, {
						readyListener: () => {
							setLearnosityReady(true);
						},
					})
				);
			});
		}
	}, [authState.accessToken, props.init]);

	useEffect(() => {
		if (learnosityReady && authorApp) {
			if (props.getAuthorApp) {
				props.getAuthorApp(authorApp);
			}
			authorApp.on('navigate', (event) => {
				if (props.navigate) {
					props.navigate(event);
				}
			});

			authorApp.on('save:success', (event) => {
				if (props.saveSuccess) {
					props.saveSuccess(event);
				}
			});

			authorApp.on('open:item', (event) => {
				if (props.openItem) {
					props.openItem(event);
				}
			});

			authorApp.on('render:item', (event) => {
				if (props.itemRendered) {
					props.itemRendered(event);
				}
			});

			authorApp.on('render:itemlist', (event) => {
				if (props.itemListRendered) {
					props.itemListRendered(event);
				}
			});

			authorApp.on('render:activity', (event) => {
				if (props.activityRendered) {
					props.activityRendered(event);
				}
			});

			authorApp.on('render:activitylist', (event) => {
				if (props.activityListRendered) {
					props.activityListRendered(event);
				}
			});

			authorApp.on('itemedit:saveable:changed', (event) => {
				if (props.itemChanged) {
					props.itemChanged(event);
				}
			});
		}

		return () => {
			if (learnosityReady && authorApp) {
				authorApp.off('open:item');
				authorApp.off('navigate');
				authorApp.off('save:success');
				authorApp.off('render:item');
				authorApp.off('render:itemlist');
				authorApp.off('render:activity');
				authorApp.off('render:activitylist');
				authorApp.off('itemedit:saveable:changed');
			}
		};
	}, [authorApp, props, learnosityReady]);

	return (
		<div>
			<div id="learnosity-author"> </div>
		</div>
	);
}
