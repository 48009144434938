import React, { useState, useMemo, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ErrorReport } from '../../../types/catConfig/errorReport';
import Errors from './Errors';
import { detectFormikHasError } from '../../../containers/catConfig/validationRules/utils/schemaValidator';

interface TextInputProps {
	name: string;
	label: string;
	onChange: (event: React.ChangeEvent) => void;
	placeholder: string;
	value: string;
	error: ErrorReport | string;
}

const TextInput = ({ name, label, onChange, placeholder, value, error }: TextInputProps) => {
	const [focused, setFocused] = useState(false);
	const target = useRef(null);
	const fieldHasError = useMemo(() => {
		return typeof error === 'object' ? detectFormikHasError(error) : false;
	}, [error]);
	const handleFocus = () => setFocused((prevState: boolean) => !prevState);

	return (
		// try using popper config
		<div className="form-group">
			<label htmlFor={name}>{label}</label>
			<div className="field" ref={target}>
				<input
					type="text"
					name={name}
					className={`form-control${fieldHasError ? ' is-invalid' : ''}`}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onFocus={handleFocus}
					onBlur={handleFocus}
				/>
			</div>
			<Overlay target={target.current} show={focused} placement={'top'}>
				{({ placement, arrowProps, show: _show, popper, ...props }) => (
					<div {...props}>
						<Errors error={error} />
					</div>
				)}
			</Overlay>
		</div>
	);
};

export function textInputFactory(
	name: string,
	label: string,
	handleChange: (event: React.ChangeEvent) => void,
	componentValuesState: { [key: string]: string },
	componentErrorsState: { [key: string]: ErrorReport }
) {
	return (
		<TextInput
			name={name}
			label={label}
			placeholder={label}
			onChange={handleChange}
			value={componentValuesState[name]}
			error={componentErrorsState[name]}
		/>
	);
}

TextInput.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TextInput;
