import React from 'react';
import GradeLevelBoundaryEntry from '../../types/catConfig/configForm/gradeLevelBoundaries';
import { ErrorReport } from '../../types/catConfig/errorReport';
import TextInput from './common/TextInput';

interface ThetaAndScaledScoreTableProps {
	handleTableChange: (event: React.ChangeEvent, key: string) => void;
	tableValues: GradeLevelBoundaryEntry[];
	tableErrors: ErrorReport[];
}

const ThetaAndScaledScoreTable = ({ handleTableChange, tableValues, tableErrors }: ThetaAndScaledScoreTableProps) => {
	return (
		<div className="container-fluid">
			<div className="row h5">
				<div className="col">Grade</div>
				<div className="col">Theta</div>
				<div className="col">ScaledScore</div>
			</div>
			{tableValues.map((entry, index) => {
				// this null check prevents errors during re-renders after a file upload
				// with a different number of grades from the current state.
				const entryError = (tableErrors[index]?.Theta ?? {}) as ErrorReport;
				return (
					<div className="row align-items-center" key={entry.Grade}>
						<div className="col">{entry.Grade}</div>
						<div className="col">
							<TextInput
								name="Theta"
								label=""
								onChange={(event) => handleTableChange(event, entry.Grade)}
								placeholder="Theta"
								value={entry.Theta}
								error={entryError}
							/>
						</div>
						<div className="col">{entry.ScaledScore}</div>
					</div>
				);
			})}
		</div>
	);
};

export default ThetaAndScaledScoreTable;
