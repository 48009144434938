import React from 'react';
import { NavLink } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

export default function AppBar(props) {
	const { authService } = useOktaAuth();

	function handleClick() {
		authService.logout(props.logoutUri);
	}
	return (
		<div>
			<nav className="bg-black">
				<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
					<div className="relative flex items-center justify-between h-16">
						<div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
							<div className="flex-shrink-0">
								<h1 className="text-xl px-4 py-1 font-thin text-white">CATnip</h1>
							</div>
							<div className="hidden sm:block sm:ml-6">
								<div className="flex">
									<NavLink
										exact
										to="/"
										className="ml-4 px-3 py-2 square-md text-sm leading-5 text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-800"
										activeClassName="text-white bg-gray-800">
										Home
									</NavLink>
									<NavLink
										to="/items"
										className="ml-4 px-3 py-2 square-md text-sm leading-5 text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-800"
										activeClassName="text-white bg-gray-800">
										Items
									</NavLink>
									<NavLink
										to="/activities"
										className="ml-4 px-3 py-2 square-md text-sm leading-5 text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-800"
										activeClassName="text-white bg-gray-800">
										Activities
									</NavLink>
									<NavLink
										to="/passages"
										className="ml-4 px-3 py-2 square-md text-sm leading-5 text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-800"
										activeClassName="text-white bg-gray-800">
										Passages
									</NavLink>

									<NavLink
										to="/templates"
										className="ml-4 px-3 py-2 square-md text-sm leading-5 text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-800"
										activeClassName="text-white bg-gray-800">
										Templates
									</NavLink>
									<NavLink
										to="/catconfig"
										className="ml-4 px-3 py-2 square-md text-sm leading-5 text-gray-300 hover:text-white hover:bg-gray-800 focus:outline-none focus:text-white focus:bg-gray-800"
										activeClassName="text-white bg-gray-800">
										Cat Config
									</NavLink>
								</div>
							</div>
						</div>
						<div className="absolute inset-y-0 right-0 flex items-center pr-2">
							<button
								className="p-1 border-2 border-transparent text-gray-400 square-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-900"
								onClick={handleClick}>
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 24 24" fill="currentcolor">
									<path d="M0 0h24v24H0z" fill="none" />
									<path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
								</svg>
							</button>
						</div>
					</div>
				</div>
			</nav>
		</div>
	);
}
