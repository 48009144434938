import React from 'react';
import getEnv from '../../envs';

export default function PreviewButtons({ itemId }) {
	function kinderPreview() {
		window.open(`${getEnv().styledPreviewUrl}?itemId=${itemId}&itemStyle=kinder`, '_blank');
	}
	function twoPlusPreviewClicked() {
		window.open(`${getEnv().styledPreviewUrl}?itemId=${itemId}&itemStyle=2Plus`, '_blank');
	}
	return (
		<>
			<div className="flex">
				<button className="lrn-btn lrn-btn-primary styled-preview-button" onClick={kinderPreview}>
					K
				</button>
				<button className="lrn-btn lrn-btn-primary styled-preview-button" onClick={twoPlusPreviewClicked}>
					2+
				</button>
			</div>
		</>
	);
}
