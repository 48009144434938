import React from 'react';

export default function NewItemTile(props) {
	return (
		<>
			<div className="border">
				<div className="bg-gray-600 text-white w-full h-16 p-2 align-middle text-center">
					<p className="text-xs">{props.title}</p>
				</div>
				<img className="w-full" src={props.tile} alt="tile" />
				<button className="bg-blue-500 hover:bg-blue-700 w-full text-white font-bold py-2 px-4" onClick={props.handleClick}>
					Create
				</button>
			</div>
		</>
	);
}
