import * as Yup from 'yup';
import { StageBehaviorOptions, StatsConfig, MonteCarloConfigOptions } from '../../types/catConfig/config';

import {
	bundleCastSchema,
	bundleFieldTestCastSchema,
	testletCastSchema,
	testletFieldTestCastSchema,
} from './validationRules/stageBehaviors';

import { SimulationSettingsCastSchema } from './validationRules/simulationSettings';
import { gradeLevelDomainsCastSchema } from './validationRules/gradeLevelDomains';
import FormStatsConfig from '../../types/catConfig/configForm/statisticalParameters';
import FormStageBehaviorOptions from '../../types/catConfig/configForm/stageBehaviorOptions';
import FormSimulationSettings from '../../types/catConfig/configForm/simulationSettings';

import { parseArrayFromCSVString } from './validationRules/utils/customValidationTests';
import GradeLevelBoundaryEntry from '../../types/catConfig/configForm/gradeLevelBoundaries';
import StatisticalParameters from '../../types/catConfig/configForm/statisticalParameters';

export function castFormToProduction(commonFields: { [key: string]: object }, productionFields: { [key: string]: object }) {
	return {
		...basicSettingsFormToFile(productionFields.basicSettings),
		GradeLevelDomains: gradeLevelDomainsFormToFile(productionFields.gradeLevelDomains),
		lowerThetaBoundaries: thetaBoundariesFormToFile(productionFields.thetaAndScaledScoreTable),
		ScaledScoreConfig: scaledScoreFormToFile(productionFields.scaledScoreConfig, productionFields.thetaAndScaledScoreTable),
		...castCommonFields(commonFields),
	};
}

export function castFormToMonteCarlo(commonFields: { [key: string]: object }, monteCarloFields: { [key: string]: object }) {
	const castedCommonFields = castCommonFields(commonFields);

	// This mess is because the field name for stage behavior options is capitalized instead of camelCase in the monteCarlo file.
	return {
		...simulationOptionsFormToFile(monteCarloFields.simulationSettings as FormSimulationSettings),
		StatsConfig: castedCommonFields.StatsConfig,
		StageBehaviorOptions: castedCommonFields.stageBehaviorOptions,
	};
}

function castCommonFields(commonFields: { [key: string]: object }) {
	return {
		StatsConfig: statsConfigFormToFile(commonFields.statsConfig as StatisticalParameters),
		stageBehaviorOptions: stageBehaviorFormToFile(commonFields.stageBehaviors as FormStageBehaviorOptions[]),
	};
}

export function statsConfigFormToFile(state: FormStatsConfig): StatsConfig {
	return Object.fromEntries(Object.entries(state).map(([k, v]) => [k, parseFloat(v!)])) as StatsConfig;
}

export function stageBehaviorFormToFile(state: FormStageBehaviorOptions[]): StageBehaviorOptions[] {
	return state.map((entry: any) => parseStageBehaviorEntry(entry));
}

function parseStageBehaviorEntry(entry: { [key: string]: string }): StageBehaviorOptions {
	delete entry['key'];
	/**
	 * estimatedGradesToSkip and chronologicalGradeLevels will always be included as properties
	 * even if the uploaded input file does not have them.
	 * The adaptive engine treats an empty list and an undefined property the same way
	 * during deserialization
	 */
	const testTypeSchemaMap: { [key: string]: Yup.AnySchema } = {
		bundle: bundleCastSchema,
		bundleFieldTest: bundleFieldTestCastSchema,
		testlet: testletCastSchema,
		testletFieldTest: testletFieldTestCastSchema,
	};
	let castEntry = testTypeSchemaMap[entry.testType].cast(entry, { assert: false, stripUnknown: true }) as StageBehaviorOptions;
	/**
	 * There is no option to omit a field if a blank value is provided as an input to a number in a Yup Schema.
	 * This is done to prevent upperBoundThetaGrade: null from appearing in the output.
	 * for some reason if(castEntry.upperBoundThetaGrade === null) did not trigger the key removal.
	 */
	if (!castEntry.upperBoundThetaGrade && castEntry.upperBoundThetaGrade !== 0) {
		delete castEntry['upperBoundThetaGrade'];
	}
	return castEntry;
}

export function simulationOptionsFormToFile(state: FormSimulationSettings): MonteCarloConfigOptions {
	return SimulationSettingsCastSchema.cast(state) as MonteCarloConfigOptions;
}

export function parseDecimalInt(value: string) {
	return parseInt(value, 10);
}

export function parseStringToArrayEntries(value: string, parseEntry: (entry: string) => string | number): (string | number)[] {
	if (value == null) {
		return [];
	}
	if (value.length === 0) {
		return [];
	}
	return parseArrayFromCSVString(value).map((entry) => parseEntry(entry));
}

export function basicSettingsFormToFile(state: any) {
	return {
		Subject: state.Subject,
		ItemPoolId: state.ItemPoolId,
		SchoolYearStartMonth: parseDecimalInt(state.SchoolYearStartMonth),
		SchoolYearStartDay: parseDecimalInt(state.SchoolYearStartDay),
	};
}

export function gradeLevelDomainsFormToFile(state: any) {
	return gradeLevelDomainsCastSchema.cast(state) as { [key: string]: string[] };
}

export function thetaBoundariesFormToFile(state: any) {
	return Object.fromEntries(state.map((entry: GradeLevelBoundaryEntry) => [entry.Grade, parseFloat(entry.Theta as string)]));
}

export function scaledScoreFormToFile(options: any, boundaries: any) {
	return {
		ThetaMultiplier: parseFloat(options.ThetaMultiplier),
		ThetaVerticalShift: parseFloat(options.ThetaVerticalShift),
		Min: parseFloat(options.Min),
		Max: parseFloat(options.Max),
		lowerBoundaries: Object.fromEntries(
			boundaries.map((entry: GradeLevelBoundaryEntry) => [entry.Grade, parseFloat(entry.ScaledScore as string)])
		),
	};
}
